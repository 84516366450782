import { ReactNode, CSSProperties } from 'react'
import styles from './DataSetIndicator.module.scss'

interface DataSetIndicatorProps {
  color: string
  children?: ReactNode
}

export const DataSetIndicator = ({
  color,
  children,
}: DataSetIndicatorProps) => {
  return (
    <span
      className={styles.dot}
      style={
        {
          '--status-dot-color': color,
        } as CSSProperties
      }
    >
      {children}
    </span>
  )
}
