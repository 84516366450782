export type FilterBy = 'volume' | 'transactions' | 'authRate'

export const MODULE_METHODS = 'module-methods'
export const MODULE_RESPONSE_CODES = 'module-response-codes'
export const MODULE_CONNECTORS = 'module-connectors'
export const MODULE_COUNTRIES = 'module-countries'
export const MODULE_CURRENCIES = 'module-currencies'
export const MODULE_SCHEMES = 'module-schemes'
export const MODULE_FLOW_RULES = 'module-flow-rules'
export const MODULE_METADATA = 'module-metadata'
