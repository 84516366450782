import { Flow } from 'shared/constants'

export const flowNames: Record<Flow, string> = {
  [Flow.checkout]: 'Checkout',
  [Flow.cardTransactions]: 'Card Transactions',
  [Flow.nonCardTransactions]: 'Other Transactions',
  [Flow.redirectTransactions]: 'Other Transactions',
}

export enum CheckoutStep {
  checkout = 'checkout',
  selectPaymentTypes = 'select-payment-types',
  transactions = 'transactions',
}

export enum CardTransactionsStep {
  transactionRequest = 'transaction-request',
  decline = 'decline',
  routeToConnection = 'route-to-connection',
  skip3dSecure = 'skip-3d-secure',
  transactionProcessing = 'transaction-processing',
}

export enum OtherTransactionsStep {
  transactionRequest = 'transaction-request',
  decline = 'decline',
  routeToConnection = 'route-to-connection',
  transactionProcessing = 'transaction-processing',
}

export enum Condition {
  ANTI_FRAUD_DECISION = 'anti_fraud_decision',
  METADATA = 'metadata',
  BROWSER_LANGUAGE = 'browser_language',
  COUNTRY = 'country',
  CARD_ISSUER_NAME = 'card_issuer_name',
  CARD_PRODUCT_NAME = 'card_product_name',
  CARD_SCHEME = 'card_scheme',
  CARD_SOURCE = 'card_source',
  CARD_BIN = 'card_bin',
  CARD_TYPE = 'card_type',
  CARD_COUNTRY = 'card_country',
  GIFT_CARD_BIN = 'gift_card_bin',
  HAS_GIFT_CARDS = 'has_gift_cards',
  IS_SUBSEQUENT_PAYMENT = 'is_subsequent_payment',
  MERCHANT_INITIATED = 'merchant_initiated',
  PAYMENT_SOURCE = 'payment_source',
  PAYMENT_METHOD = 'payment_method',
  PRODUCT_CATEGORIES = 'product_categories',
  PRODUCT_TYPES = 'product_types',
  SKUS = 'skus',
}

export enum Operation {
  IS_ONE_OF = 'is_one_of',
  IS_NOT_ONE_OF = 'is_not_one_of',
  INCLUDES = 'includes',
  INCLUDES_AT_LEAST_ONE = 'includes_at_least_one',
  INCLUDES_ALL = 'includes_all',
  INCLUDES_NONE = 'includes_none',
  EXCLUDES = 'excludes',
  IS_BETWEEN = 'is_between',
  GREATER_THAN = 'greater_than',
  LESS_THAN = 'less_than',
  EQUAL_TO = 'equal_to',
  LIKE_ONE_OF = 'like_one_of',
  UNLIKE_ANY_OF = 'unlike_any_of',
}

export enum AmountCondition {
  ANY_AMOUNT_OR_CURRENCY = '',
  CURRENCY = 'currency',
  AMOUNT = 'amount',
  AMOUNT_ZERO = 'amount_zero',
}
