import { Box, Description, Flex, Stack } from '@gr4vy/poutine-react'
import { ColumnDef } from '@tanstack/react-table'
import { ReactNode, useContext } from 'react'
import { InsightsFilterContext } from 'insights/contexts/InsightsFilterContext'
import { InsightsModuleQuery } from 'insights/hooks/use-module-data'
import { useModuleIconUrl } from 'insights/hooks/use-module-icon-url'
import { InsightsModuleData } from 'insights/services/insights'
import currencyFormat from 'shared/helpers/currency-format'
import { DataTable } from './DataTable'
import { Meter } from './Meter'

interface ModuleProps {
  query: InsightsModuleQuery
  title: ReactNode
}

const MOCKED_MAX_VALUE = 29066828

export const Module = ({ query, title }: ModuleProps) => {
  const { data, loading, error, filterKey, onReload } = query
  const { filters, setFilters } = useContext(InsightsFilterContext)
  const currencyOptions: Intl.NumberFormatOptions = {
    currency: filters.currency,
    currencyDisplay: 'narrowSymbol',
  }

  const columns: ColumnDef<InsightsModuleData>[] = [
    {
      accessorKey: 'key',
      header: () => title,
      cell: function Cell({ row }) {
        const iconUrl = useModuleIconUrl(row.original)
        const { displayName, value } = row.original

        return (
          <Stack gap={4}>
            <Flex justifyContent="space-between" alignItems="center" gap={8}>
              <Box width="full" minWidth={0}>
                <Description>
                  <Description.Text>{displayName}</Description.Text>
                  {!!iconUrl && <Description.Icon src={iconUrl} size={24} />}
                </Description>
              </Box>
              <Box>{currencyFormat(value, currencyOptions)}</Box>
            </Flex>
            <Meter value={value} maxValue={MOCKED_MAX_VALUE} />
          </Stack>
        )
      },
    },
  ]
  return (
    <DataTable
      columns={columns}
      data={data}
      selectedRowKey={filters[filterKey]}
      onRowKeyToggle={(key, isSelected) => {
        setFilters({
          ...filters,
          [filterKey]: isSelected ? key : undefined,
        })
      }}
      loading={loading}
      error={error}
      onReload={onReload}
    />
  )
}
