import {
  Icon,
  Text,
  Badge,
  Dropdown,
  Description,
  Modal,
} from '@gr4vy/poutine-react'
import { WebhookSubscriptionDeactivateModal } from 'integrations/components/WebhookSubscriptionDeactivateModal'
import { WebhookSubscriptionRemoveModal } from 'integrations/components/WebhookSubscriptionRemoveModal'
import { useMutateWebhookSubscription } from 'integrations/hooks/use-mutate-webhook-subscription'
import { useWebhookSubscriptionPermission } from 'integrations/hooks/use-webhook-subscription-permission'
import { WebhookSubscription } from 'integrations/services/webhookSubscriptions'
import { ColumnDef, DataTable } from 'shared/components/DataTable'
import { QueryResult } from 'shared/services/client'

export type WebhookSubscriptionTableProps = {
  page: QueryResult<WebhookSubscription>
  onEdit: (id: string) => void
}

const columns: Array<ColumnDef<WebhookSubscription>> = [
  {
    header: 'URL',
    accessorKey: 'url',
    size: 400,
    cell: ({ row }) => (
      <Description>
        <Description.Text>{row.original.url}</Description.Text>
      </Description>
    ),
  },
  {
    header: 'Merchant',
    accessorKey: 'merchantAccountId',
    size: 150,
    cell: ({ row }) => (
      <Description>
        <Description.Text>
          {row.original.merchantAccountId ?? 'All merchants'}
        </Description.Text>
      </Description>
    ),
  },
  {
    header: 'Status',
    accessorKey: 'status',
    size: 150,
    cell: ({ row }) => {
      return (
        <Badge
          variant="subtle"
          color={row.original.active ? 'positive' : 'neutral'}
        >
          {row.original.active ? 'Active' : 'Inactive'}
        </Badge>
      )
    },
  },
]

const { useModal } = Modal

export const WebhookSubscriptionTable = ({
  page,
  onEdit,
}: WebhookSubscriptionTableProps) => {
  const { data, isLoading } = page
  const { onActivate } = useMutateWebhookSubscription()
  const { canManageWebhookSubscriptions } = useWebhookSubscriptionPermission()
  const { openModal, closeModal, isModalOpen } = useModal()

  const actionsColumn: Array<ColumnDef<WebhookSubscription>> = [
    {
      header: 'Actions',
      accessorKey: 'actions',
      size: 80,
      cell: function Actions({ row }) {
        const removeModalId = `REMOVE-${row.original.id}`
        const deactivateModalId = `DEACTIVATE-${row.original.id}`

        const onToggle = () => {
          row.original.active
            ? openModal(deactivateModalId)
            : onActivate(row.original.id, row.original.merchantAccountId)
        }

        return (
          <>
            <Dropdown>
              <Dropdown.Trigger asChild>
                <Dropdown.Button variant="tertiary" size="small">
                  <Icon name="more-horizontal" />
                </Dropdown.Button>
              </Dropdown.Trigger>
              <Dropdown.Content align="end">
                <Dropdown.Item
                  opensInModal
                  onSelect={() => onEdit(row.original.id)}
                >
                  Edit
                </Dropdown.Item>
                <Dropdown.Item opensInModal onClick={onToggle}>
                  {row.original.active ? 'Deactivate' : 'Activate'}
                </Dropdown.Item>
                <Dropdown.Item
                  opensInModal
                  onClick={() => openModal(removeModalId)}
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Content>
            </Dropdown>
            {isModalOpen(removeModalId) && (
              <WebhookSubscriptionRemoveModal
                webhookSubscription={row.original}
                onClose={() => closeModal(removeModalId)}
              />
            )}
            {isModalOpen(deactivateModalId) && (
              <WebhookSubscriptionDeactivateModal
                webhookSubscription={row.original}
                onClose={() => closeModal(deactivateModalId)}
              />
            )}
          </>
        )
      },
    },
  ]

  return (
    <DataTable
      data={data}
      loading={isLoading}
      columns={[
        ...columns,
        ...(canManageWebhookSubscriptions ? actionsColumn : []),
      ]}
    >
      <DataTable.Empty>
        <Text variant="reg4" textAlign="center">
          No subscriptions have been added.
        </Text>
      </DataTable.Empty>
      <DataTable.Loading>Loading...</DataTable.Loading>
    </DataTable>
  )
}
