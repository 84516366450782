import { GridItem } from '@gr4vy/poutine-react'
import { Select } from 'antd'
import { Operation } from 'flows/constants'
import { useFlow } from 'session/hooks/use-flow'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { Action } from 'shared/constants'
import { maxTagPlaceholderFormatter } from 'shared/helpers/maxTagPlaceholderFormatter'

interface CardSourceInputProps {
  name: number
}

const cardSources = [
  { value: 'applepay', name: 'Apple Pay' },
  { value: 'raw', name: 'Card' },
  { value: 'click-to-pay', name: 'Click to Pay' },
  { value: 'googlepay', name: 'Google Pay' },
  { value: 'network-token', name: 'Network token' },
  { value: 'token', name: 'Tokenized card' },
]

const filteredSources: Record<Action, string[]> = {
  [Action.skip3ds]: ['applepay'],
  [Action.declineEarly]: [],
  [Action.routeTransaction]: [],
  [Action.selectPaymentOption]: [],
}

const ERROR_CARD_SOURCE_REQUIRED = 'At least one card source is required'
const ERROR_OPERATOR_REQUIRED = 'Operator is required'

const CardSourceInput = ({ name }: CardSourceInputProps) => {
  const { action } = useFlow()

  const hideUnsupportedSources = ({ value }: { value: string }) =>
    !filteredSources[action]?.includes(value)

  return (
    <>
      <GridItem gridColumn="span 2">
        <FormItem
          label={<Label>Operator</Label>}
          name={[name, 'operator']}
          rules={[{ required: true, message: ERROR_OPERATOR_REQUIRED }]}
        >
          <Select placeholder="Select an operator">
            <Select.Option value={Operation.IS_ONE_OF}>includes</Select.Option>
            <Select.Option value={Operation.IS_NOT_ONE_OF}>
              excludes
            </Select.Option>
          </Select>
        </FormItem>
      </GridItem>
      <GridItem gridColumn="span 6">
        <FormItem
          label={<Label>Value(s)</Label>}
          name={[name, 'value']}
          rules={[{ required: true, message: ERROR_CARD_SOURCE_REQUIRED }]}
        >
          <Select
            mode="multiple"
            allowClear
            maxTagCount="responsive"
            maxTagPlaceholder={maxTagPlaceholderFormatter}
            placeholder="Select an option"
          >
            {cardSources
              .filter(hideUnsupportedSources)
              .map(({ value, name }) => (
                <Select.Option value={value} key={value}>
                  {name}
                </Select.Option>
              ))}
          </Select>
        </FormItem>
      </GridItem>
    </>
  )
}

export { ERROR_CARD_SOURCE_REQUIRED, ERROR_OPERATOR_REQUIRED }
export default CardSourceInput
