import {
  Box,
  Button,
  Flex,
  Icon,
  Stack,
  Table,
  Text,
} from '@gr4vy/poutine-react'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  RowSelectionState,
  useReactTable,
} from '@tanstack/react-table'
import { useEffect, useMemo, useState } from 'react'
import { useTableBodyHeight } from 'insights/hooks/use-table-body-height'
import { InsightsModuleData } from 'insights/services/insights'
import { useKeyboardListNavigation } from 'shared/hooks/use-keyboard-list-navigation'
import styles from './DataTable.module.scss'

interface DataTableProps {
  columns: ColumnDef<InsightsModuleData>[]
  data: InsightsModuleData[]
  onRowKeyToggle?: (key: string, isSelected: boolean) => void
  selectedRowKey?: string
  loading?: boolean
  error?: boolean
  onReload?: () => void
}

export function DataTable({
  columns,
  data,
  onRowKeyToggle,
  selectedRowKey,
  loading,
  error,
  onReload,
}: DataTableProps) {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
  const { onKeyDown } = useKeyboardListNavigation()
  const { tableWrapperRef, tableBodyHeight } = useTableBodyHeight({
    data,
    error,
    loading,
  })

  const items = useMemo(
    () => (loading || error || !data ? [] : data),
    [loading, error, data]
  )

  useEffect(() => {
    setRowSelection(selectedRowKey ? { [selectedRowKey]: true } : {})
  }, [selectedRowKey])

  const table = useReactTable({
    data: items,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    enableMultiRowSelection: false,
    state: {
      rowSelection,
    },
    getRowId: (row) => row.key,
  })

  return (
    <div ref={tableWrapperRef} className={styles.tableWrapper}>
      <Table>
        <Table.Header className={styles.header}>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Table.HeaderCell key={header.id} className={styles.cell}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </Table.HeaderCell>
                )
              })}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body
          style={{
            height: tableBodyHeight,
          }}
        >
          {!loading &&
            table.getRowModel().rows.map((row) => (
              <Table.Row
                key={row.id}
                data-state={
                  table.getIsSomeRowsSelected()
                    ? row.getIsSelected()
                      ? 'selected'
                      : 'disabled'
                    : undefined
                }
                onClick={() => {
                  row.toggleSelected()
                  if (onRowKeyToggle) {
                    onRowKeyToggle(row.original.key, !row.getIsSelected())
                  }
                }}
                className={styles.clickableRow}
                onKeyDown={onKeyDown}
                tabIndex={0}
              >
                {row.getVisibleCells().map((cell) => (
                  <Table.BodyCell key={cell.id} className={styles.cell}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.BodyCell>
                ))}
              </Table.Row>
            ))}
          {loading && !table.getRowModel().rows.length && (
            <Table.Row>
              <Table.EmptyCell colSpan={columns.length}>
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                >
                  <Icon
                    name="loader-spinner"
                    width={32}
                    height={32}
                    className={styles.spinner}
                  />
                </Flex>
              </Table.EmptyCell>
            </Table.Row>
          )}
          {!loading && error && (
            <Table.Row>
              <Table.EmptyCell
                colSpan={columns.length}
                className={styles.error}
              >
                <Stack gap={16} alignItems="center">
                  <Box textAlign="center">
                    <Text as="span" fontWeight="bold">
                      The response code module has encountered an error and
                      could not load.
                    </Text>
                    <Text>Use the reload button to try again.</Text>
                  </Box>
                  <Button variant="tertiary" onClick={onReload}>
                    Reload <Icon name="reload" />
                  </Button>
                </Stack>
              </Table.EmptyCell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </div>
  )
}
