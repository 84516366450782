import { Modal, Button, Flex, Stack, Text } from '@gr4vy/poutine-react'
import { useMutateWebhookSubscription } from 'integrations/hooks/use-mutate-webhook-subscription'
import { WebhookSubscription } from 'integrations/services/webhookSubscriptions'

interface WebhookSubscriptionDeactivateModalProps {
  webhookSubscription: WebhookSubscription
  onClose: () => void
}

export const WebhookSubscriptionDeactivateModal = ({
  webhookSubscription,
  onClose,
}: WebhookSubscriptionDeactivateModalProps) => {
  const { update, onToggleSuccess } = useMutateWebhookSubscription()
  const { id, merchantAccountId } = webhookSubscription

  return (
    <Modal
      open
      type="information"
      title="Do you want to deactivate this webhook?"
      onOpenChange={(status) => {
        !status && onClose()
      }}
    >
      <Stack gap={24}>
        <Text>
          By deactivating this webhook you will no longer receive webhooks for
          this URL. You will be able to reactivate the webhook at any time.
        </Text>
        <Flex justifyContent="flex-end">
          <Button variant="secondary" marginRight={16} onClick={onClose}>
            No, cancel
          </Button>
          <Button
            loading={update.isPending}
            loadingText="Deactivating..."
            onClick={() => {
              update.mutate(
                {
                  id,
                  merchantAccountId,
                  active: false,
                },
                { onSuccess: onToggleSuccess, onSettled: onClose }
              )
            }}
          >
            Yes, deactivate
          </Button>
        </Flex>
      </Stack>
    </Modal>
  )
}
