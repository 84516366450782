import { Flex, GridItem, Icon, Stack, Tooltip } from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import { DataVisualization } from 'insights/components/DataVisualization'
import { Module } from 'insights/components/Module'
import { Section } from 'insights/components/Section'
import { InsightsFilterContext } from 'insights/contexts/InsightsFilterContext'
import { useModuleData } from 'insights/hooks/use-module-data'
import { PageLayout } from 'shared/components/PageLayout'
import { Selector } from 'shared/components/Selector/Selector'
import { pathToInsights } from 'shared/paths'

type InsightsPageProps = {
  title: string
}

const getTabs = (merchantAccountId?: string) =>
  merchantAccountId
    ? [
        {
          title: 'Authorization',
          url: pathToInsights.authorization(merchantAccountId),
        },
      ]
    : []

const InsightsPage = ({ title }: InsightsPageProps) => {
  const { merchantAccountId } = useParams<{ merchantAccountId: string }>()
  const {
    methods,
    responseCodes,
    connectors,
    countries,
    currencies,
    flowRulesApplied: flowRules,
    metadata,
  } = useModuleData([
    'methods',
    'responseCodes',
    'connectors',
    'countries',
    'currencies',
    'flowRulesApplied',
    'metadata',
  ])

  return (
    <PageLayout title={title} subNavigation={getTabs(merchantAccountId)}>
      <Stack flexDirection="column" gap={56}>
        <Selector
          currencies={
            currencies?.data?.map((currency) => ({
              value: currency?.key,
              label: currency?.displayName,
            })) || []
          }
          filterByOptions={['volume', 'transactions', 'authRate']}
          filterContext={InsightsFilterContext}
        />
        <DataVisualization showGettingStarted={false} />
        <Section title="Request">
          <GridItem gridColumn="span 4">
            <Module query={responseCodes} title="Response codes" />
          </GridItem>
          <GridItem gridColumn="span 4">
            <Module query={methods} title="Methods" />
          </GridItem>
          <GridItem gridColumn="span 4">
            <Module
              query={metadata}
              title={
                <Flex alignItems="center" gap={4}>
                  Metadata
                  <Tooltip content="Top 25 Metadata in descending order">
                    <Icon name="info" size="small" />
                  </Tooltip>
                </Flex>
              }
            />
          </GridItem>
        </Section>
        <Section title="Countries">
          <GridItem gridColumn="span 4">
            <Module query={countries} title="Country" />
          </GridItem>
          <GridItem gridColumn="span 4">
            <Module query={connectors} title="Connectors" />
          </GridItem>
          <GridItem gridColumn="span 4">
            <Module query={flowRules} title="Flow rule applied" />
          </GridItem>
        </Section>
      </Stack>
    </PageLayout>
  )
}

export default InsightsPage
