import { Button, Icon, Modal } from '@gr4vy/poutine-react'
import { useState } from 'react'
import { WebhookSubscriptionDrawer } from 'integrations/components/WebhookSubscriptionDrawer'
import { WebhookSubscriptionTable } from 'integrations/components/WebhookSubscriptionTable'
import { useIntegrationRoutes } from 'integrations/hooks/use-integration-routes'
import { useWebhookSubscription } from 'integrations/hooks/use-webhook-subscription'
import { useWebhookSubscriptionPermission } from 'integrations/hooks/use-webhook-subscription-permission'
import { WebhookSubscription } from 'integrations/services/webhookSubscriptions'
import { PageLayout } from 'shared/components/PageLayout'

const { useModal } = Modal

const WebhookSubscriptionsBrowsePage = ({ title }: { title: string }) => {
  const navigationTabs = useIntegrationRoutes()
  const { closeModal, isModalOpen, openModal } = useModal()
  const { page } = useWebhookSubscription()
  const { canManageWebhookSubscriptions } = useWebhookSubscriptionPermission()
  const [subscription, setSubscription] = useState<
    WebhookSubscription | undefined
  >()

  const handleEdit = (id: string) => {
    const edit = page?.data?.items?.find((s) => s.id === id)
    setSubscription(edit)
    openModal('addWebhookSubscription')
  }

  const handleClose = () => {
    closeModal('addWebhookSubscription')
    setSubscription(undefined)
  }

  return (
    <>
      <PageLayout
        title={title}
        subNavigation={navigationTabs}
        actions={
          canManageWebhookSubscriptions ? (
            <Button
              variant="primary"
              onClick={() => openModal('addWebhookSubscription')}
            >
              <Icon name="add-plus" />
              Add webhook subscription
            </Button>
          ) : null
        }
      >
        <WebhookSubscriptionTable page={page} onEdit={handleEdit} />
      </PageLayout>
      <WebhookSubscriptionDrawer
        open={isModalOpen('addWebhookSubscription')}
        onClose={handleClose}
        subscription={subscription}
      />
    </>
  )
}

export default WebhookSubscriptionsBrowsePage
