import { createContext, Context } from 'react'

export type FilterContextProps<T> = {
  filters: Partial<T>
  setFilters: (filters: Partial<T>) => void
}

export function createFilterContext<T>(): Context<FilterContextProps<T>> {
  return createContext<FilterContextProps<T>>({
    filters: {},
    setFilters: () => {},
  })
}

export default createFilterContext
