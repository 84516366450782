import { useParams } from 'react-router-dom'
import { usePaymentServiceDefinitions } from 'connections/hooks'
import { getCountryFlag } from 'insights/helpers/get-country-flag'
import { InsightsModuleData, InsightsModule } from 'insights/services/insights'
import {
  useCardSchemeDefinitions,
  usePaymentMethodDefinitions,
} from 'shared/hooks'

type InsightsModuleWithIcon = Extract<
  InsightsModule,
  'countries' | 'connectors' | 'schemes' | 'methods'
>

export const useModuleIconUrl = (moduleData: InsightsModuleData) => {
  const { merchantAccountId } = useParams<{ merchantAccountId: string }>()
  const { key, metadata, module } = moduleData

  const cardSchemeDefinitions = useCardSchemeDefinitions()
  const { paymentServiceDefinitions } = usePaymentServiceDefinitions()
  const paymentMethodDefinitions =
    usePaymentMethodDefinitions(merchantAccountId)

  const moduleIconUrlMap: Record<
    InsightsModuleWithIcon,
    () => string | undefined
  > = {
    countries: () => getCountryFlag(key),
    connectors: () =>
      metadata?.paymentServiceDefinitionId
        ? paymentServiceDefinitions[metadata.paymentServiceDefinitionId]
            ?.iconUrl
        : undefined,
    schemes: () => cardSchemeDefinitions[key]?.iconUrl,
    methods: () => paymentMethodDefinitions[key]?.iconUrl,
  } as const

  return module && module in moduleIconUrlMap
    ? moduleIconUrlMap[module as InsightsModuleWithIcon]()
    : undefined
}
