import { Grid, GridItem } from '@gr4vy/poutine-react'
import { Totals } from 'insights/components/Totals'
import { useDataVisualizationData } from 'insights/hooks/use-data-visualization-data'
import { LineChart, LineChartGettingStarted } from 'shared/components/LineChart'

export interface DataVisualizationProps {
  showGettingStarted: boolean
}

export const DataVisualization = ({
  showGettingStarted,
}: DataVisualizationProps) => {
  const { summary, filterBy, getTotalsData, getLineChartData } =
    useDataVisualizationData()

  return (
    <Grid columnGap={16}>
      <GridItem gridColumn="span 3">
        <Totals
          showGettingStarted={showGettingStarted}
          {...getTotalsData(filterBy, summary)}
        />
      </GridItem>
      <GridItem gridColumn="span 9">
        {showGettingStarted ? (
          <LineChartGettingStarted />
        ) : (
          <LineChart {...getLineChartData(filterBy)} />
        )}
      </GridItem>
    </Grid>
  )
}
