import { isArray } from 'lodash'
import { Key, ReactNode } from 'react'

interface DisplayValueType {
  key?: Key
  value?: string | number
  label?: ReactNode
  title?: ReactNode
  disabled?: boolean
}

export const maxTagPlaceholderFormatter = (omitted: DisplayValueType[]) => {
  if (omitted.length > 20) {
    return <span title={`+ ${omitted.length} ...`}>+ {omitted.length} ...</span>
  }

  const title = omitted.reduce((displayValue, current) => {
    const formattedLabel = isArray(current.label)
      ? (current.label as string[])?.join(' ')
      : (current.label as string)
    if (displayValue.length === 0) {
      return formattedLabel
    }
    return `${displayValue}, ${formattedLabel}`
  }, '')

  return <span title={title}>+ {omitted.length} ...</span>
}
