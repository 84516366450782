import { ColorBlue60, ColorGray60 } from '@gr4vy/poutine-tokens'
import dayjs from 'dayjs'
import { SeriesOption } from 'echarts'
import { InsightsModuleData } from 'insights/services/insights'
import { defaultSeriesSettings } from 'shared/constants/line-chart-config'
import { generateHourRange } from 'shared/helpers/line-chart'

export const sevenDaysSeries: SeriesOption[] = [
  {
    name: 'Current',
    data: [
      34793, 32640, 30962, 29694, 31936, 34441, 36929, 35749, 34540, 33991,
      32592, 31875, 30723, 29578, 28487, 27488, 26699, 25504, 24484, 23559,
      22941, 21412, 20652, 19732, 49573, 47658, 59735, 65545, 79109, 86228,
      97436, 10921, 11753, 12545, 13714, 14440, 15851, 16831, 17877, 18589,
      19870, 20477, 21867, 22942, 23703, 24662, 25530, 26860, 27969, 12781,
      56672, 44796, 64481, 54002, 74748, 69295, 88096, 79535, 94511, 86439,
      10667, 95160, 11944, 10526, 12493, 11648, 13702, 12583, 14443, 13662,
      15495, 14833, 16536, 15652, 32672, 34970, 35480, 36796, 37909, 38646,
      39739, 40648, 41724, 42584, 43884, 44899, 45425, 46494, 47696, 48901,
      49925, 50593, 51732, 52771, 53728, 54610, 55545, 56613, 57944, 19759,
      54510, 53482, 52930, 51678, 50451, 49669, 48793, 47973, 46779, 45405,
      44670, 43964, 42663, 41501, 40565, 39549, 38656, 37682, 36533, 35630,
      34646, 33681, 32446, 31711, 10558, 23554, 24594, 25493, 26433, 27941,
      28419, 29983, 30802, 31523, 32843, 33755, 34784, 35533, 36873, 37744,
      38746, 39436, 40739, 41655, 42909, 43783, 44448, 45574, 46626, 84450,
      50427, 51469, 52644, 53545, 54649, 55686, 56650, 57402, 58594, 59891,
      60477, 61932, 62467, 63921, 64853, 65899, 66682, 67960, 68630,
    ],
    color: ColorBlue60,
    ...defaultSeriesSettings,
  },
  {
    name: 'Previous',
    data: [
      17987, 18291, 19210, 20654, 21583, 22833, 23471, 24634, 25751, 26436,
      27599, 28491, 29464, 30897, 31701, 32773, 33537, 34298, 35146, 36802,
      37835, 38500, 39326, 40884, 22888, 21535, 20523, 19503, 18635, 17845,
      16394, 15837, 14420, 13852, 12346, 11559, 10650, 9450, 8353, 7516, 6495,
      5170, 4940, 3357, 2340, 1986, 427, 3881, 40952, 41749, 42900, 43638,
      44641, 45167, 46721, 47746, 48742, 49427, 50760, 51333, 52847, 53650,
      54192, 55230, 56405, 57527, 58307, 59903, 60966, 61197, 62589, 63803,
      60256, 59381, 58406, 57978, 56378, 55247, 54524, 53875, 52698, 51211,
      50832, 49657, 48882, 47765, 46382, 45428, 44887, 43977, 42370, 41110,
      40178, 39256, 38611, 37196, 29363, 28688, 27642, 26884, 25734, 24989,
      23434, 22594, 21371, 20412, 19592, 18258, 17594, 16590, 15673, 14687,
      13158, 12223, 11951, 10483, 9803, 8164, 7388, 6216, 33331, 34997, 35486,
      36933, 37301, 38423, 39855, 40315, 41536, 42561, 43560, 44542, 45811,
      46463, 47156, 48238, 49140, 50533, 51629, 52652, 53325, 54888, 55373,
      56269, 31991, 38981, 3536, 67313, 53775, 34455, 29420, 32950, 53110,
      63661, 16920, 8251, 79280, 94245, 141, 83273, 2208, 60618, 39035, 43407,
      88499, 81253, 26080, 46369, 68049,
    ],
    color: ColorGray60,
    ...defaultSeriesSettings,
  },
]

export const sevenDaysRange = generateHourRange(
  dayjs().subtract(7, 'day').hour(dayjs().hour()).toISOString(),
  169
)

export const responseCodes: InsightsModuleData[] = [
  {
    key: 'invalid_billing_address',
    displayName: 'invalid_billing_address',
    value: 5086695,
  },
  {
    key: 'invalid_service_request',
    displayName: 'invalid_service_request',
    value: 4069356,
  },
  {
    key: 'invalid_tax_identifier',
    displayName: 'invalid_tax_identifier',
    value: 3051267,
  },
  {
    key: 'missing_phone_number',
    displayName: 'missing_phone_number',
    value: 2542848,
  },
  {
    key: 'missing_shipping_address',
    displayName: 'missing_shipping_address',
    value: 2034678,
  },
  {
    key: 'unsupported_currency',
    displayName: 'unsupported_currency',
    value: 1830210,
  },
  {
    key: 'unexpected_state',
    displayName: 'unexpected_state',
    value: 1731726,
  },
]

export const connectors: InsightsModuleData[] = [
  {
    key: 'mastercard-card',
    displayName: 'Mastercard',
    value: 6104034,
    metadata: {
      paymentServiceDefinitionId: 'mastercard-card',
    },
  },
  {
    key: 'paypal',
    displayName: 'PayPal',
    value: 4882695,
    metadata: {
      paymentServiceDefinitionId: 'paypal-paypal',
    },
  },
  {
    key: 'adyen-card',
    displayName: 'Adyen',
    value: 3052017,
    metadata: {
      paymentServiceDefinitionId: 'adyen-card',
    },
  },
  {
    key: 'klarna',
    displayName: 'Klarna',
    value: 2646678,
    metadata: {
      paymentServiceDefinitionId: 'klarna-klarna',
    },
  },
  {
    key: 'cybersource',
    displayName: 'Cybersource',
    value: 1646678,
    metadata: {
      paymentServiceDefinitionId: 'cybersource-card',
    },
  },
]

export const countries: InsightsModuleData[] = [
  {
    key: 'US',
    displayName: 'United States',
    value: 21674574,
  },
  {
    key: 'GB',
    displayName: 'United Kingdom',
    value: 11345363,
  },
  {
    key: 'CA',
    displayName: 'Canada',
    value: 9123124,
  },
]

export const methods: InsightsModuleData[] = [
  {
    key: 'card',
    displayName: 'Card',
    value: 20852017,
  },
  {
    key: 'paypal',
    displayName: 'PayPal',
    value: 10852091,
  },
  {
    key: 'klarna',
    displayName: 'Klarna',
    value: 7772021,
  },
  {
    key: 'spei',
    displayName: 'Bank (Spei)',
    value: 7192378,
  },
  {
    key: 'googlepay',
    displayName: 'Gift card',
    value: 4238890,
  },
]

export const metadata: InsightsModuleData[] = [
  {
    key: 'experiment=a',
    displayName: 'experiment=a',
    value: 17238112,
  },
  {
    key: 'experiment=b',
    displayName: 'experiment=b',
    value: 9872314,
  },
  {
    key: 'experiment=c',
    displayName: 'experiment=c',
    value: 8823192,
  },
]

export const flowRulesApplied: InsightsModuleData[] = [
  {
    key: 'skip_3ds_if_low_risk',
    displayName: 'Skip 3DS if low risk',
    value: 21267891,
  },
  {
    key: 'force_3ds_if_high_risk',
    displayName: 'Force 3DS if high risk, otherwise decline',
    value: 9081723,
  },
  {
    key: 'attempt_3ds_if_review_needed',
    displayName: 'Attempt 3DS if review needed',
    value: 4182678,
  },
]
