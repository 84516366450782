import { Text, TextLink } from '@gr4vy/poutine-react'
import { useMutateWebhookSubscription } from 'integrations/hooks/use-mutate-webhook-subscription'
import { WebhookSubscription } from 'integrations/services/webhookSubscriptions'
import { ModalRemove } from 'shared/components/Modal/ModalRemove'

export interface WebhookSubscriptionRemoveModalProps {
  webhookSubscription: WebhookSubscription
  onClose: () => void
}

export const WebhookSubscriptionRemoveModal = ({
  webhookSubscription,
  onClose,
}: WebhookSubscriptionRemoveModalProps) => {
  const { merchantAccountId, id, url } = webhookSubscription
  const { remove } = useMutateWebhookSubscription()

  return (
    <ModalRemove
      title="Do you want to permanently delete this webhook?"
      loading={remove.isPending}
      loadingText="Removing..."
      onCancel={onClose}
      onOk={() => {
        remove.mutate({
          merchantAccountId,
          id,
        })
      }}
      cancelText="No, cancel"
    >
      <Text>
        By deleting this webhook you will no longer receive webhooks for this
        URL. If you wish to start receiving webhooks for the same URL you must
        create a new subscription.
      </Text>
      <Text fontWeight="bold">
        Webhook:{' '}
        <TextLink href={url} target="_blank" style={{ display: 'inline' }}>
          {url}
        </TextLink>
      </Text>
    </ModalRemove>
  )
}
