import InsightsFilterProvider from 'insights/contexts/InsightsFilterProvider'
import View from './InsightsPage'

const InsightsPage = ({ title }: { title: string }) => {
  return (
    <InsightsFilterProvider>
      <View title={title} />
    </InsightsFilterProvider>
  )
}

export default InsightsPage
