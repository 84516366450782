import { Box, Icon, Stack, atoms } from '@gr4vy/poutine-react'
import { useClickOutside } from '@mantine/hooks'
import clsx from 'clsx'
import { LineChartProps } from 'shared/constants/line-chart-config'
import { useLineChart } from 'shared/hooks'
import styles from './LineChart.module.scss'

export const LineChart = ({
  data,
  series,
  loading,
  numberFormat,
  showLegend = true,
}: LineChartProps) => {
  const {
    echart: { lineChartRef, echartsInstance },
    tooltip: { showTooltip, setShowTooltip, setTooltipRef, onCloseTooltip },
  } = useLineChart({ loading, data, series, numberFormat, showLegend })

  const containerRef = useClickOutside(() => setShowTooltip(false), ['click'])

  return (
    <Stack width="full" position="relative" asChild>
      <div ref={containerRef}>
        {loading && (
          <Box className={styles.loading}>
            <Icon
              name="loader-spinner"
              width={48}
              height={48}
              color="blue100"
            />
          </Box>
        )}
        <div ref={lineChartRef} className={styles.lineChart} />
        {echartsInstance && (
          <div
            className={clsx(!showTooltip && atoms({ display: 'none' }))}
            onClick={onCloseTooltip}
            ref={setTooltipRef}
          />
        )}
      </div>
    </Stack>
  )
}
