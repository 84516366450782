import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { LIST_PAYMENT_METHOD_DEFINITIONS } from 'shared/constants'
import {
  listPaymentMethodDefinitions,
  PaymentMethodDefinition,
} from 'shared/services'

export const usePaymentMethodDefinitions = (merchantAccountId?: string) => {
  const definitionsQuery = useQuery({
    queryKey: [LIST_PAYMENT_METHOD_DEFINITIONS, merchantAccountId],
    queryFn: () => listPaymentMethodDefinitions(),
    gcTime: 1000 * 60 * 15,
    staleTime: 1000 * 60 * 15,
    enabled: !!merchantAccountId,
  })

  const definitions = useMemo(
    () =>
      (definitionsQuery.data?.items ?? []).reduce(
        (prev, curr) => ({
          ...prev,
          [curr.id]: {
            ...curr,
            typeof: [
              'applepay',
              'googlepay',
              'googlepay_pan_only',
              'click-to-pay',
            ].includes(curr.id)
              ? 'card'
              : undefined,
          },
        }),
        {}
      ) as Record<string, PaymentMethodDefinition>,
    [definitionsQuery.data]
  )

  return definitions
}
