import { FilterBy } from 'insights/constants/insights'
import client from 'shared/services/client'

export interface InsightsSeriesData {
  firstInterval: string
  values: number[]
}

export interface InsightsSeries {
  current: InsightsSeriesData
  previous: InsightsSeriesData
}

export interface InsightsSummary {
  current?: {
    value?: number | null
  }
  previous?: {
    value?: number | null
  }
  delta?: number | null
}

export interface InsightsTotalData extends InsightsSummary {
  value: FilterBy
  disabled: boolean
  loading: boolean
}

export interface InsightsTotal {
  summary: InsightsSummary
  series: InsightsSeries
}

export type InsightsModule =
  | 'countries'
  | 'currencies'
  | 'instruments'
  | 'methods'
  | 'issuerNames'
  | 'cardTypes'
  | 'issuerCountries'
  | 'schemes'
  | 'responseCodes'
  | 'transactionTypes'
  | 'subsequentPayments'
  | 'initiators'
  | 'authorizations'
  | 'connectors'
  | 'flowRulesApplied'
  | 'metadata'
  | 'retries'
  | 'bins'

export interface InsightsModuleData {
  key: string
  displayName: string
  value: number
  metadata?: Record<string, string>
  module?: InsightsModule
}

export type Metric = 'volume' | 'transactions' | 'auth_rate'

export type Period = '1-day' | '7-days' | '30-days'

export interface InsightsAuthTotalParams {
  period: Period
  currency?: Uppercase<string>
  limit?: number
  cursor?: string | null
}

export const getInsightsAuthTotals = async (
  metric: Metric,
  params: Pick<InsightsAuthTotalParams, 'period' | 'currency'>
) => {
  const response = await client.get<InsightsTotal>(
    `/metrics-explorer/authorization/metrics/${metric}/totals`,
    {
      params,
    }
  )

  return response.data
}

export const getInsightsAuthCurrencies = async (
  params: Pick<InsightsAuthTotalParams, 'period'>
) => {
  const response = await client.get<Array<InsightsModuleData>>(
    '/metrics-explorer/authorization/metrics/transactions/modules/currency',
    {
      params,
    }
  )

  return response.data
}
