import { useQueries, useQuery, keepPreviousData } from '@tanstack/react-query'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { InsightsFilters } from 'insights/constants/filter'
import {
  MODULE_CONNECTORS,
  MODULE_COUNTRIES,
  MODULE_CURRENCIES,
  MODULE_FLOW_RULES,
  MODULE_METADATA,
  MODULE_METHODS,
  MODULE_RESPONSE_CODES,
} from 'insights/constants/insights'
import {
  connectors,
  countries,
  flowRulesApplied,
  metadata,
  methods,
  responseCodes,
} from 'insights/constants/mocks'
import { InsightsFilterContext } from 'insights/contexts/InsightsFilterContext'
import { transformModuleData } from 'insights/helpers/transform-module'
import { typedEntries } from 'insights/helpers/typed-entries'
import {
  InsightsModule,
  InsightsModuleData,
  getInsightsAuthCurrencies,
  Period,
} from 'insights/services/insights'

export interface InsightsModuleQuery {
  data: InsightsModuleData[]
  loading: boolean
  error: boolean
  filterKey: keyof Omit<InsightsFilters, 'filterBy' | 'currency' | 'period'>
  onReload?: () => void
}

const moduleConfig = {
  methods: {
    queryKey: MODULE_METHODS,
    data: methods,
    filterKey: 'method',
  },
  connectors: {
    queryKey: MODULE_CONNECTORS,
    data: connectors,
    filterKey: 'paymentServiceId',
  },
  countries: {
    queryKey: MODULE_COUNTRIES,
    data: countries,
    filterKey: 'country',
  },
  flowRulesApplied: {
    queryKey: MODULE_FLOW_RULES,
    data: flowRulesApplied,
    filterKey: 'ruleId',
  },
  metadata: {
    queryKey: MODULE_METADATA,
    data: metadata,
    filterKey: 'metadata',
  },
  responseCodes: {
    queryKey: MODULE_RESPONSE_CODES,
    data: responseCodes,
    filterKey: 'errorCode',
  },
  currencies: {
    queryKey: MODULE_CURRENCIES,
    data: [] as InsightsModuleData[],
    filterKey: 'currency',
  },
} as const

const getModuleData = async (
  module: InsightsModule
): Promise<InsightsModuleData[]> => {
  return new Promise((resolve, reject) =>
    setTimeout(() => {
      // Simulate an error randomly
      if (Math.floor(Math.random() * 2) === 0) {
        reject(new Error('Error'))
      } else {
        resolve(moduleConfig[module as keyof typeof moduleConfig].data)
      }
    }, 1000)
  )
}

export const useModuleData = (modules: InsightsModule[]) => {
  const { filters } = useContext(InsightsFilterContext)
  const { merchantAccountId } = useParams<{
    merchantAccountId: string
  }>()

  const queries = useQueries({
    queries: typedEntries(moduleConfig).map(([module, config]) => ({
      queryKey: [config.queryKey, filters.period, filters.currency],
      queryFn: () => getModuleData(module),
      select: (data: InsightsModuleData[]) => transformModuleData(data, module),
      initialData: [],
      enabled: modules.includes(module),
      meta: {
        error: {
          ignore: true,
        },
      },
      retry: false,
      placeholderData: keepPreviousData,
    })),
  })

  const moduleData = queries.reduce(
    (acc, query, index) => {
      const module = Object.keys(moduleConfig)[
        index
      ] as keyof typeof moduleConfig
      acc[module] = query
      return acc
    },
    {} as Record<keyof typeof moduleConfig, (typeof queries)[number]>
  )

  const currenciesQuery = useQuery({
    queryKey: [MODULE_CURRENCIES, merchantAccountId, filters.period],
    queryFn: () =>
      getInsightsAuthCurrencies({ period: filters.period as Period }),
    select: (data: InsightsModuleData[]) =>
      transformModuleData(data, 'currencies'),
    enabled: !!filters.period && modules.includes('currencies'),
    retry: false,
    placeholderData: keepPreviousData,
    initialData: [],
    meta: {
      error: {
        ignore: [404],
      },
    },
  })

  return {
    methods: {
      data: moduleData.methods.data,
      loading: moduleData.methods.isFetching,
      error: moduleData.methods.isError,
      filterKey: moduleConfig.methods.filterKey,
      onReload: moduleData.methods.refetch,
    },
    responseCodes: {
      data: moduleData.responseCodes.data,
      loading: moduleData.responseCodes.isFetching,
      error: moduleData.responseCodes.isError,
      filterKey: moduleConfig.responseCodes.filterKey,
      onReload: moduleData.responseCodes.refetch,
    },
    connectors: {
      data: moduleData.connectors.data,
      loading: moduleData.connectors.isFetching,
      error: moduleData.connectors.isError,
      filterKey: moduleConfig.connectors.filterKey,
      onReload: moduleData.connectors.refetch,
    },
    countries: {
      data: moduleData.countries.data,
      loading: moduleData.countries.isFetching,
      error: moduleData.countries.isError,
      filterKey: moduleConfig.countries.filterKey,
      onReload: moduleData.countries.refetch,
    },
    flowRulesApplied: {
      data: moduleData.flowRulesApplied.data,
      loading: moduleData.flowRulesApplied.isFetching,
      error: moduleData.flowRulesApplied.isError,
      filterKey: moduleConfig.flowRulesApplied.filterKey,
      onReload: moduleData.flowRulesApplied.refetch,
    },
    metadata: {
      data: moduleData.metadata.data,
      loading: moduleData.metadata.isFetching,
      error: moduleData.metadata.isError,
      filterKey: moduleConfig.metadata.filterKey,
      onReload: moduleData.metadata.refetch,
    },
    currencies: {
      data: currenciesQuery.data,
      loading: currenciesQuery.isFetching,
      error: currenciesQuery.isError,
      filterKey: moduleConfig.currencies.filterKey,
      onReload: currenciesQuery.refetch,
    },
  }
}
