import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  addWebhookSubscription,
  removeWebhookSubscription,
  updateWebhookSubscription,
  WebhookSubscription,
} from 'integrations/services/webhookSubscriptions'
import { WEBHOOK_SUBSCRIPTIONS } from 'shared/constants/query-keys'
import { handleErrorResponse } from 'shared/utils/handleErrorResponse'
import { handleSuccessResponse } from 'shared/utils/handleSuccessResponse'

export const useMutateWebhookSubscription = () => {
  const queryClient = useQueryClient()

  const invalidateWebhookSubscriptions = () =>
    queryClient.invalidateQueries({ queryKey: [WEBHOOK_SUBSCRIPTIONS] })

  const remove = useMutation({
    mutationFn: removeWebhookSubscription,
    onSuccess: handleSuccessResponse(
      'Webhook subscription successfully deleted.',
      invalidateWebhookSubscriptions
    ),
    onError: handleErrorResponse,
  })

  const create = useMutation({
    mutationFn: addWebhookSubscription,
    onSuccess: handleSuccessResponse(
      'Webhook subscription successfully added.',
      invalidateWebhookSubscriptions
    ),
    onError: handleErrorResponse,
  })

  const update = useMutation({
    mutationFn: updateWebhookSubscription,
    onSuccess: handleSuccessResponse(
      'Webhook subscription successfully updated.',
      invalidateWebhookSubscriptions
    ),
    onError: handleErrorResponse,
  })

  const onActivate = (id: string, merchantAccountId: string | undefined) =>
    update.mutate(
      {
        id,
        merchantAccountId,
        active: true,
      },
      { onSuccess: onToggleSuccess }
    )

  const onToggleSuccess = (data: WebhookSubscription) => {
    handleSuccessResponse(
      `Webhook subscription successfully ${data.active ? 'activated' : 'deactivated'}.`,
      invalidateWebhookSubscriptions
    )()
  }

  return {
    remove,
    create,
    update,
    onActivate,
    onToggleSuccess,
  }
}
