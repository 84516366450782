import { useContext, useEffect } from 'react'
import { Tab } from 'home/constants/health-dashboard'
import { HomeFilterContext } from 'home/contexts/HomeFilterContext'
import { generateTransactionsDateRange } from 'home/helpers/data'
import { useTotalTransactions } from 'home/hooks/use-total-transactions'
import { useTotalVolume } from 'home/hooks/use-total-volume'
import {
  HealthDashboardVolumeSummary,
  HealthDashboardTransactionsSummary,
} from 'home/services/health-dashboard'
import { LineChartProps } from 'shared/constants/line-chart-config'
import currencyFormat from 'shared/helpers/currency-format'
import {
  formatAmount,
  getPercentageTrend,
} from 'shared/helpers/line-chart-format'

export type FilterBy = 'volume' | 'transactions'

export const useHealthDashboard = () => {
  const { filters, setFilters } = useContext(HomeFilterContext)
  const {
    totalVolume,
    isLoadingTotalVolume,
    isErrorTotalVolume,
    volumeSeries,
    volumeDateRange,
  } = useTotalVolume(filters)

  const {
    totalTransactions,
    isLoadingTotalTransactions,
    isErrorTotalTransactions,
    transactionsSeries,
    transactionsDateRange,
  } = useTotalTransactions(filters)

  useEffect(() => {
    const series = totalVolume?.series || totalTransactions?.series
    if (series) {
      const { afterCreatedAt, beforeCreatedAt } =
        generateTransactionsDateRange(series)

      setFilters({
        ...filters,
        afterCreatedAt,
        beforeCreatedAt,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalVolume?.series, totalTransactions?.series])

  const currencyOptions: Intl.NumberFormatOptions = {
    currency: filters.currency,
    currencyDisplay: 'narrowSymbol',
  }

  const getTriggerProps = (
    value: Tab,
    summary:
      | HealthDashboardVolumeSummary
      | HealthDashboardTransactionsSummary
      | undefined
  ) => {
    const transactionsSummary =
      !!summary && 'transactionsAccepted' in summary ? summary : undefined
    const volumeSummary =
      !!summary && 'transactionsAuthorized' in summary ? summary : undefined

    return {
      volume: {
        title: 'Volume',
        amount: currencyFormat(
          volumeSummary?.transactionsAuthorized || 0,
          currencyOptions
        ),
        value: 'volume',
        trend: getPercentageTrend(
          'volume',
          volumeSummary?.transactionsAuthorizedDeltaPct
        ),
        disabled: isLoadingTotalVolume || isErrorTotalVolume,
        loading: isLoadingTotalVolume,
      },
      transactions: {
        title: 'Transactions',
        amount: formatAmount(
          transactionsSummary?.transactionsAccepted || 0,
          'decimal'
        ),
        value: 'transactions',
        trend: getPercentageTrend(
          'transactions',
          transactionsSummary?.transactionsAcceptedDeltaPct
        ),
        disabled: isLoadingTotalTransactions || isErrorTotalTransactions,
        loading: isLoadingTotalTransactions,
      },
      authRate: {
        title: 'Auth rate',
        amount:
          typeof summary?.transactionsAuthorizedRate === 'number'
            ? `${summary.transactionsAuthorizedRate}%`
            : '-',
        value: 'authRate',
        trend: getPercentageTrend(
          'authRate',
          summary?.transactionsAuthorizedRateDelta
        ),
        loading: isLoadingTotalVolume || isLoadingTotalTransactions,
      },
      refunds: {
        title: 'Refunds',
        amount:
          filters.filterBy !== 'transactions'
            ? currencyFormat(summary?.refunds || 0, {
                currency: filters.currency,
                currencyDisplay: 'narrowSymbol',
              })
            : formatAmount(summary?.refunds || 0, 'decimal'),
        value: 'refunds',
        trend: getPercentageTrend('refunds', summary?.refundsDeltaPct),
        loading: isLoadingTotalVolume || isLoadingTotalTransactions,
      },
    }[value]
  }

  const getLineChartData = (filterBy: FilterBy, tab: Tab): LineChartProps =>
    ({
      volume: {
        data: volumeDateRange,
        series: volumeSeries.volume,
        loading: isLoadingTotalVolume,
        numberFormat: 'decimalCompact' as const,
      },
      transactions: {
        data: transactionsDateRange,
        series: transactionsSeries.transactions,
        loading: isLoadingTotalTransactions,
        numberFormat: 'decimal' as const,
      },
      authRate: {
        data: volumeDateRange,
        series:
          filterBy !== 'transactions'
            ? volumeSeries.authRate
            : transactionsSeries.authRate,
        loading: isLoadingTotalVolume || isLoadingTotalTransactions,
        numberFormat: 'percent' as const,
      },
      refunds: {
        data: volumeDateRange,
        series:
          filterBy !== 'transactions'
            ? volumeSeries.refunds
            : transactionsSeries.refunds,
        loading: isLoadingTotalVolume || isLoadingTotalTransactions,
        numberFormat:
          filterBy !== 'transactions'
            ? ('decimalCompact' as const)
            : ('decimal' as const),
      },
    })[tab]

  const filterBy =
    !!filters.filterBy && ['volume', 'transactions'].includes(filters.filterBy)
      ? (filters.filterBy as FilterBy)
      : 'volume'

  return {
    summary:
      filterBy === 'volume' ? totalVolume?.summary : totalTransactions?.summary,
    getTriggerProps,
    getLineChartData,
    filterBy,
  }
}
