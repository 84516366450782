import client, { CollectionResponse } from 'shared/services/client'

export interface WebhookAuthentication {
  kind: string
  username: string | null
  password: string | null
}

export interface WebhookSubscription {
  id: string
  merchantAccountId?: string
  active: boolean
  url: string
  authentication?: WebhookAuthentication | null
}

export type MutateWebhookSubscription = Partial<
  Omit<WebhookSubscription, 'id'>
> & {
  id: string
}

export type NewWebhookSubscription = Pick<
  WebhookSubscription,
  'url' | 'authentication' | 'merchantAccountId'
>

export const listWebhookSubscriptions = async (): Promise<
  CollectionResponse<WebhookSubscription>
> => {
  const response = await client.get('/webhook-subscriptions', {
    headers: { 'X-GR4VY-MERCHANT-ACCOUNT-ID': '*' },
  })

  return response.data
}

export const addWebhookSubscription = async ({
  merchantAccountId,
  ...rest
}: NewWebhookSubscription) => {
  const response = await client.post('/webhook-subscriptions', rest, {
    headers: { 'X-GR4VY-MERCHANT-ACCOUNT-ID': merchantAccountId ?? '*' },
  })
  return response.data
}

export const removeWebhookSubscription = async ({
  id,
  merchantAccountId,
}: MutateWebhookSubscription) => {
  await client.delete(`/webhook-subscriptions/${id}`, {
    headers: { 'X-GR4VY-MERCHANT-ACCOUNT-ID': merchantAccountId ?? '*' },
  })
}

export const updateWebhookSubscription = async ({
  id,
  merchantAccountId,
  ...payload
}: MutateWebhookSubscription) => {
  const response = await client.put<WebhookSubscription>(
    `/webhook-subscriptions/${id}`,
    payload,
    {
      headers: { 'X-GR4VY-MERCHANT-ACCOUNT-ID': merchantAccountId ?? '*' },
    }
  )
  return response.data
}
