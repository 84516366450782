import { Description, Heading, Stack, Text } from '@gr4vy/poutine-react'
import { Summary } from 'shared/components/Summary'
import { formatBin } from 'transactions/helpers/masked-card-number'
import { TransactionEvent } from 'transactions/services'
import styles from './styles.module.scss'

const getMaskedCard = (cardBin: string, cardSuffix: string) => {
  const formattedBin = formatBin(cardBin)
  return `${formattedBin}•• •••• ${cardSuffix}`
}

export const RealTimeAccountUpdate = ({
  event,
}: {
  event: TransactionEvent
}) => {
  const hasUpdate =
    !!event.context['newCardBin'] ||
    !!event.context['newCardSuffix'] ||
    !!event.context['newExpirationDate']

  return event ? (
    <Stack gap={32}>
      <Stack gap={8} alignItems="flex-start">
        <Heading as="h5" level={5}>
          Existing card details
        </Heading>
        <Summary className={styles.summary} inline>
          <Summary.Key>Account number</Summary.Key>
          <Summary.Value fallback={!event.context.status}>
            <Description>
              <Description.Text fontFamily="mono">
                {getMaskedCard(
                  event.context['cardBin'],
                  event.context['cardSuffix']
                )}
              </Description.Text>
            </Description>
          </Summary.Value>
        </Summary>
        <Summary className={styles.summary} inline>
          <Summary.Key>Expiration date</Summary.Key>
          <Summary.Value fallback={!event.context.status}>
            <Description>
              <Description.Text fontFamily="mono">
                {event.context['cardExpirationDate'] || '-'}
              </Description.Text>
            </Description>
          </Summary.Value>
        </Summary>
      </Stack>

      {hasUpdate && (
        <Stack gap={8} alignItems="flex-start">
          <Heading as="h5" level={5}>
            Updated card details
          </Heading>
          <Summary className={styles.summary} inline>
            <Summary.Key>Account number</Summary.Key>
            <Summary.Value fallback={!event.context.status}>
              <Description>
                <Description.Text fontFamily="mono">
                  {getMaskedCard(
                    event.context['newCardBin'],
                    event.context['newCardSuffix']
                  )}
                </Description.Text>
              </Description>
            </Summary.Value>
          </Summary>
          <Summary className={styles.summary} inline>
            <Summary.Key>Expiration date</Summary.Key>
            <Summary.Value fallback={!event.context.status}>
              <Description>
                <Description.Text fontFamily="mono">
                  {event.context['newCardExpirationDate'] || '-'}
                </Description.Text>
              </Description>
            </Summary.Value>
          </Summary>
        </Stack>
      )}

      <Stack gap={8} alignItems="flex-start">
        <Heading as="h5" level={5}>
          Latency
        </Heading>
        <Text>
          {event.context['latency'] ? event.context['latency'] + 'ms' : 'N/A'}
        </Text>
      </Stack>

      {!!event.context['responseCode'] && (
        <Stack gap={8} alignItems="flex-start">
          <Heading as="h5" level={5}>
            Response code
          </Heading>
          <Text fontFamily="mono">{event.context['responseCode']}</Text>
        </Stack>
      )}

      {!!event.context['errorCode'] && (
        <Stack gap={8} alignItems="flex-start">
          <Heading as="h5" level={5}>
            Error code
          </Heading>
          <Text fontFamily="mono">{event.context['errorCode']}</Text>
        </Stack>
      )}
    </Stack>
  ) : null
}
