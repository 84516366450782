import { Text, atoms } from '@gr4vy/poutine-react'
import { ReactNode } from 'react'

export interface HelpTextProps {
  children: ReactNode
}

export const HelpText = ({ children }: HelpTextProps) => (
  <Text as="span" className={atoms({ fontSize: 'xs', paddingTop: 4 })}>
    {children}
  </Text>
)
