import { ColorBlue60, ColorGray70 } from '@gr4vy/poutine-tokens'
import { addDays, addHours } from 'date-fns'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { SeriesOption } from 'echarts'
import { Tab } from 'home/constants/health-dashboard'
import {
  HealthDashboardSeriesTransactionsData,
  HealthDashboardSeriesVolumeData,
  HealthDashboardTotalTransactions,
  HealthDashboardTotalVolume,
  HealthDashboardTransactionsSeries,
  HealthDashboardVolumeSeries,
  Period,
} from 'home/services/health-dashboard'
import {
  InsightsSeries,
  InsightsSeriesData,
  InsightsTotal,
} from 'insights/services/insights'
import {
  defaultSeriesSettings,
  LineChartProps,
} from 'shared/constants/line-chart-config'
import {
  generateDayRange,
  generateHourRange,
  getDuration,
  getSeriesNameByPeriod,
  nullifyZeroedData,
} from 'shared/helpers/line-chart'
dayjs.extend(duration)

interface GenerateSeriesProps {
  series:
    | HealthDashboardVolumeSeries
    | HealthDashboardTransactionsSeries
    | InsightsSeries
    | undefined
  period: Period
}

export const generateSeries = ({
  series,
  period,
}: GenerateSeriesProps): Record<Tab, SeriesOption[]> => {
  if (series) {
    const currentLabelName = getSeriesNameByPeriod(period).current
    const previousLabelName = getSeriesNameByPeriod(period).previous

    const transactionsCurrent =
      'transactionsAccepted' in series.current ? series.current : undefined
    const transactionsPrevious =
      'transactionsAccepted' in series.previous ? series.previous : undefined
    const volumeCurrent =
      'transactionsAuthorized' in series.current ? series.current : undefined
    const volumePrevious =
      'transactionsAuthorized' in series.previous ? series.previous : undefined
    const authRateCurrent =
      'transactionsAuthorizedRate' in series.current
        ? series.current
        : undefined
    const authRatePrevious =
      'transactionsAuthorizedRate' in series.previous
        ? series.previous
        : undefined
    const refundsCurrent =
      'refunds' in series.current ? series.current : undefined
    const refundsPrevious =
      'refunds' in series.previous ? series.previous : undefined
    const insightsCurrent =
      'values' in series.current ? series.current : undefined
    const insightsPrevious =
      'values' in series.previous ? series.previous : undefined

    return {
      volume: [
        {
          name: currentLabelName,
          data:
            volumeCurrent?.transactionsAuthorized || insightsCurrent?.values,
          color: ColorBlue60,
          ...defaultSeriesSettings,
        },
        {
          name: previousLabelName,
          data:
            volumePrevious?.transactionsAuthorized || insightsPrevious?.values,
          color: ColorGray70,
          ...defaultSeriesSettings,
        },
      ],
      transactions: [
        {
          name: currentLabelName,
          data:
            transactionsCurrent?.transactionsAccepted ||
            insightsCurrent?.values,
          color: ColorBlue60,
          ...defaultSeriesSettings,
        },
        {
          name: previousLabelName,
          data:
            transactionsPrevious?.transactionsAccepted ||
            insightsPrevious?.values,
          color: ColorGray70,
          ...defaultSeriesSettings,
        },
      ],
      authRate: [
        {
          name: currentLabelName,
          data:
            authRateCurrent?.transactionsAuthorizedRate ||
            insightsCurrent?.values,
          color: ColorBlue60,
          ...defaultSeriesSettings,
        },
        {
          name: previousLabelName,
          data:
            authRatePrevious?.transactionsAuthorizedRate ||
            insightsPrevious?.values,
          color: ColorGray70,
          ...defaultSeriesSettings,
        },
      ],
      refunds: [
        {
          name: currentLabelName,
          data: refundsCurrent?.refunds,
          color: ColorBlue60,
          ...defaultSeriesSettings,
        },
        {
          name: previousLabelName,
          data: refundsPrevious?.refunds,
          color: ColorGray70,
          ...defaultSeriesSettings,
        },
      ],
    }
  }

  return {
    volume: [],
    transactions: [],
    authRate: [],
    refunds: [],
  }
}

const extractDataFromSeries = (
  series:
    | HealthDashboardVolumeSeries
    | HealthDashboardTransactionsSeries
    | InsightsSeries
) => {
  const [timestamp, period] = series.current.firstInterval.split('/')
  let length

  if ('values' in series.current) {
    length = series.current.values.length
  } else {
    length =
      'transactionsAuthorized' in series.current
        ? series.current.transactionsAuthorized.length
        : series.current.transactionsAccepted.length
  }

  return { timestamp, length, duration: getDuration(period, length) }
}

export const generateDateRange = (
  series:
    | HealthDashboardVolumeSeries
    | HealthDashboardTransactionsSeries
    | InsightsSeries
    | undefined
): LineChartProps['data'] => {
  if (series?.current.firstInterval) {
    const { timestamp, length, duration } = extractDataFromSeries(series)

    return duration.days
      ? generateDayRange(timestamp, length)
      : generateHourRange(timestamp, length)
  }

  return []
}

export const generateTransactionsDateRange = (
  series: HealthDashboardVolumeSeries | HealthDashboardTransactionsSeries
) => {
  const { timestamp, duration } = extractDataFromSeries(series)

  const afterCreatedAt = new Date(timestamp).toISOString()
  const beforeCreatedAt = duration.hours
    ? addHours(afterCreatedAt, duration.hours).toISOString()
    : addDays(afterCreatedAt, duration.days).toISOString()

  return {
    afterCreatedAt,
    beforeCreatedAt,
  }
}

const transformZeroedSeriesData = <
  T extends
    | HealthDashboardSeriesVolumeData
    | HealthDashboardSeriesTransactionsData
    | InsightsSeriesData,
>(
  data: T
) => {
  if ('values' in data) {
    return data
  }

  const modifiedData = { ...data, refunds: nullifyZeroedData(data.refunds) }

  return 'transactionsAuthorized' in data
    ? {
        ...modifiedData,
        transactionsAuthorized: nullifyZeroedData(data.transactionsAuthorized),
      }
    : {
        ...modifiedData,
        transactionsAccepted: nullifyZeroedData(data.transactionsAccepted),
      }
}

export const transformSeriesData = <
  T extends
    | HealthDashboardTotalVolume
    | HealthDashboardTotalTransactions
    | InsightsTotal,
>(
  data: T
) => ({
  ...data,
  series: {
    ...data.series,
    current: transformZeroedSeriesData(data.series.current),
    previous: transformZeroedSeriesData(data.series.previous),
  },
})
