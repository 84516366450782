export enum Integration {
  apiKeys = 'apiKeys',
  apiLogs = 'apiLogs',
  webhookSubscriptions = 'webhookSubscriptions',
}

export const integrationNames: Record<Integration, string> = {
  [Integration.apiKeys]: 'API Keys',
  [Integration.apiLogs]: 'API Logs',
  [Integration.webhookSubscriptions]: 'Webhook subscriptions',
}
