import { ColorBlue60, ColorGray60 } from '@gr4vy/poutine-tokens'
import { addDays, addHours } from 'date-fns'
import { SeriesOption } from 'echarts'
import { Period } from 'home/services/health-dashboard'
import { InsightsTotal, InsightsSeries } from 'insights/services/insights'
import {
  defaultSeriesSettings,
  LineChartProps,
} from 'shared/constants/line-chart-config'
import {
  generateDayRange,
  generateHourRange,
  getDuration,
  getSeriesNameByPeriod,
} from 'shared/helpers/line-chart'

interface GenerateSeriesProps {
  series: InsightsSeries | undefined
  period: Period
}

export const generateSeries = ({
  series,
  period,
}: GenerateSeriesProps): SeriesOption[] => {
  if (series) {
    const currentLabelName = getSeriesNameByPeriod(period).current
    const previousLabelName = getSeriesNameByPeriod(period).previous

    return [
      {
        name: currentLabelName,
        data: series?.current?.values ?? [],
        color: ColorBlue60,
        ...defaultSeriesSettings,
      },
      {
        name: previousLabelName,
        data: series?.previous?.values ?? [],
        color: ColorGray60,
        ...defaultSeriesSettings,
      },
    ]
  }

  return []
}

const extractDataFromSeries = (series: InsightsSeries) => {
  const [timestamp, period] = series.current.firstInterval.split('/')
  const length = series.current.values?.length ?? 0

  return { timestamp, length, duration: getDuration(period, length) }
}

export const generateDateRange = (
  series: InsightsSeries | undefined
): LineChartProps['data'] => {
  if (series?.current.firstInterval) {
    const { timestamp, length, duration } = extractDataFromSeries(series)

    return duration.days
      ? generateDayRange(timestamp, length)
      : generateHourRange(timestamp, length)
  }

  return []
}

export const generateTransactionsDateRange = (series: InsightsSeries) => {
  const { timestamp, duration } = extractDataFromSeries(series)

  const afterCreatedAt = new Date(timestamp).toISOString()
  const beforeCreatedAt = duration.hours
    ? addHours(afterCreatedAt, duration.hours).toISOString()
    : addDays(afterCreatedAt, duration.days).toISOString()

  return {
    afterCreatedAt,
    beforeCreatedAt,
  }
}

export const transformSeriesData = <T extends InsightsTotal>(data: T) => ({
  ...data,
  series: {
    ...data.series,
    current: data.series.current,
    previous: data.series.previous,
  },
})
