import { useEffect } from 'react'
import { matchPath, useSearchParams, useLocation } from 'react-router-dom'
import { getItem, setItem } from 'shared/helpers/localstorage'
import { deserialize, serialize } from 'shared/helpers/serialize'
import { HOME_PATH } from 'shared/paths/home'

export interface Filters {
  limit?: number
}

const DEFAULT_FILTERS = {
  limit: 20,
}

export const LIMIT_KEY = 'gr4vy-filters-limit'
export const EXCLUDED_PATHS = ['/home']

export const useFilters = <T extends Filters>(customFilters?: Partial<T>) => {
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams(
    serialize({
      ...DEFAULT_FILTERS,
      ...customFilters,
      limit: customFilters?.limit || getItem(LIMIT_KEY, DEFAULT_FILTERS.limit),
    })
  )

  const setFilters = (newFilters: Partial<T>) => {
    setSearchParams(serialize(newFilters))
  }

  const filters: Partial<T> = deserialize(searchParams.toString())

  useEffect(() => {
    const shouldPersistLimit = !isHomePath(location.pathname) && !!filters.limit

    if (shouldPersistLimit) {
      setItem(LIMIT_KEY, filters.limit)
    }
  }, [filters.limit, location.pathname])

  return [filters, setFilters] as const
}

const isHomePath = (pathname: string) => {
  return matchPath(
    {
      path: HOME_PATH,
      end: false,
    },
    pathname
  )
}
