import { InsightsModule, InsightsModuleData } from 'insights/services/insights'

export const transformModuleData = (
  data: InsightsModuleData[],
  module: InsightsModule
) =>
  data.map<InsightsModuleData>((item) => ({
    ...item,
    module,
  }))
