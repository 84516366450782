import { Select, SelectProps } from 'antd'

export enum Types {
  ANY = 'any',
  BETWEEN = 'between',
  GREATER_THAN_OR_EQUAL = 'greater_than_or_equal',
  LESS_THAN_OR_EQUAL = 'less_than_or_equal',
  EQUAL_TO = 'equal_to',
}

export const types = [
  {
    code: Types.ANY,
    label: 'Any amount',
  },
  {
    code: Types.EQUAL_TO,
    label: 'equal to',
  },
  {
    code: Types.LESS_THAN_OR_EQUAL,
    label: 'less than or equal',
  },
  {
    code: Types.GREATER_THAN_OR_EQUAL,
    label: 'greater than or equal',
  },
  {
    code: Types.BETWEEN,
    label: 'between',
  },
]

type OperatorSelectProps = {
  value?: string
  onChange?: SelectProps['onSelect']
}

const OperatorSelect = ({ value, onChange }: OperatorSelectProps) => {
  return (
    <Select onSelect={onChange} value={value}>
      {types.map(({ code, label }) => (
        <Select.Option key={code} value={code}>
          {label}
        </Select.Option>
      ))}
    </Select>
  )
}

export default OperatorSelect
