import { Grid, GridItem } from '@gr4vy/poutine-react'
import { FormInstance } from 'antd'
import { useParams } from 'react-router-dom'
import { AntiFraudDecisionInput } from 'flows/components/conditions/AntiFraudDecisionInput'
import { BinRangeInput } from 'flows/components/conditions/BinRangeInput'
import { BooleanInput } from 'flows/components/conditions/BooleanInput'
import { BrowserLanguageInput } from 'flows/components/conditions/BrowserLanguageInput'
import { CardIssuerOrProductName } from 'flows/components/conditions/CardIssuerOrProductName'
import { CardSchemaInput } from 'flows/components/conditions/CardSchemaInput'
import { CardSourceInput } from 'flows/components/conditions/CardSourceInput'
import { CardTypeInput } from 'flows/components/conditions/CardTypeInput'
import { ConditionSelect } from 'flows/components/conditions/ConditionSelect'
import { CountryInput } from 'flows/components/conditions/CountryInput'
import { MetadataInput } from 'flows/components/conditions/MetadataInput'
import { PaymentMethodInput } from 'flows/components/conditions/PaymentMethodInput'
import { PaymentSourceInput } from 'flows/components/conditions/PaymentSourceInput'
import { ProductCategoryInput } from 'flows/components/conditions/ProductCategoryInput'
import { ProductTypeInput } from 'flows/components/conditions/ProductTypeInput'
import { RemoveCondition } from 'flows/components/conditions/RemoveCondition'
import { SkuInput } from 'flows/components/conditions/SkuInput'
import { Condition, RuleConfigCondition } from 'flows/constants'
import { useOutcomeOptions } from 'flows/hooks/useOutcomeOptions'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { Action, Flow } from 'shared/constants'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import { getInitValues, getOptions } from './helpers'

interface ConditionRowProps {
  form: FormInstance
  name: number
  conditions: RuleConfigCondition[]
  remove: () => void
}

export const ConditionRow = ({
  form,
  name: conditionIndex,
  conditions: availableConditions,
  remove,
}: ConditionRowProps) => {
  const { merchantAccountId } = useParams() as {
    merchantAccountId: string
  }
  const { options: outcomeOptions } = useOutcomeOptions(
    merchantAccountId,
    Flow.redirectTransactions,
    Action.routeTransaction
  )
  const hasWriteAccess = useResourcePermission(
    Resource.flows,
    AccessLevel.write
  )
  const conditions = form.getFieldValue('conditions')
  const name = form.getFieldValue(['conditions', conditionIndex, 'name'])
  const options = getOptions(name, conditions, availableConditions)

  const handleChangeCondition = (condition: Condition) => {
    if (condition !== name) {
      conditions[conditionIndex] = getInitValues(condition, availableConditions)
      form.setFieldsValue({ conditions })
    }
  }

  return (
    <Grid data-testid={`${name}-condition`}>
      <GridItem gridColumn="span 3">
        <FormItem label={<Label>Condition type</Label>}>
          <ConditionSelect
            value={name}
            options={options}
            onSelect={handleChangeCondition}
          />
        </FormItem>
      </GridItem>
      {name === Condition.ANTI_FRAUD_DECISION && (
        <AntiFraudDecisionInput name={conditionIndex} />
      )}
      {name === Condition.BROWSER_LANGUAGE && (
        <BrowserLanguageInput name={conditionIndex} />
      )}
      {name === Condition.CARD_BIN && <BinRangeInput name={conditionIndex} />}
      {name === Condition.CARD_COUNTRY && (
        <CountryInput name={conditionIndex} />
      )}
      {name === Condition.CARD_ISSUER_NAME && (
        <CardIssuerOrProductName name={conditionIndex} placeholder="Barclays" />
      )}
      {name === Condition.CARD_PRODUCT_NAME && (
        <CardIssuerOrProductName
          name={conditionIndex}
          placeholder="Visa Electron"
        />
      )}
      {name === Condition.CARD_SCHEME && (
        <CardSchemaInput name={conditionIndex} />
      )}
      {name === Condition.CARD_SOURCE && (
        <CardSourceInput name={conditionIndex} />
      )}
      {name === Condition.CARD_TYPE && <CardTypeInput name={conditionIndex} />}
      {name === Condition.COUNTRY && <CountryInput name={conditionIndex} />}
      {name === Condition.HAS_GIFT_CARDS && (
        <BooleanInput name={conditionIndex} />
      )}
      {name === Condition.GIFT_CARD_BIN && (
        <BinRangeInput name={conditionIndex} maxLength={9} />
      )}
      {name === Condition.IS_SUBSEQUENT_PAYMENT && (
        <BooleanInput name={conditionIndex} />
      )}
      {name === Condition.MERCHANT_INITIATED && (
        <BooleanInput name={conditionIndex} />
      )}
      {name === Condition.METADATA && <MetadataInput name={conditionIndex} />}
      {name === Condition.PAYMENT_METHOD && (
        <PaymentMethodInput name={conditionIndex} options={outcomeOptions} />
      )}
      {name === Condition.PAYMENT_SOURCE && (
        <PaymentSourceInput name={conditionIndex} />
      )}
      {name === Condition.PRODUCT_CATEGORIES && (
        <ProductCategoryInput name={conditionIndex} />
      )}
      {name === Condition.PRODUCT_TYPES && (
        <ProductTypeInput name={conditionIndex} />
      )}
      {name === Condition.SKUS && <SkuInput name={conditionIndex} />}
      {/*TODO - what happens if we find a condition we can't handle?*/}
      {hasWriteAccess && (
        <GridItem gridColumn="span 1" justifySelf="center">
          <div style={{ position: 'relative', top: 37 }}>
            <RemoveCondition remove={remove} />
          </div>
        </GridItem>
      )}
    </Grid>
  )
}
