import { Grid, GridItem } from '@gr4vy/poutine-react'
import { Tabs } from 'home/components/Tabs'
import { LineChartGettingStarted } from 'shared/components/LineChart'

export const DataVisualizationGettingStarted = () => (
  <Tabs defaultValue="volume">
    <Grid>
      <GridItem gridColumn="span 3">
        <Tabs.List>
          <Tabs.Trigger
            title="Volume"
            amount="$0.0"
            value="volume"
            trend={<></>}
            loading={false}
            disabled
          />
          <Tabs.Trigger
            title="Auth rate"
            amount="-"
            value="authRate"
            trend={<></>}
            loading={false}
            disabled
          />
          <Tabs.Trigger
            title="Refunds"
            amount="$0.0"
            value="refunds"
            trend={<></>}
            loading={false}
            disabled
          />
        </Tabs.List>
      </GridItem>
      <GridItem gridColumn="span 9">
        <LineChartGettingStarted />
      </GridItem>
    </Grid>
  </Tabs>
)
