import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  generateDateRange,
  generateSeries,
  transformSeriesData,
} from 'home/helpers/data'
import { Period } from 'home/services/health-dashboard'
import { InsightsFilters } from 'insights/constants/filter'
import { getInsightsAuthTotals } from 'insights/services/insights'
import { INSIGHTS_AUTH_TOTAL_AUTHRATE } from 'shared/constants'

export const useAuthTotalAuthrate = (filters: Partial<InsightsFilters>) => {
  const { merchantAccountId } = useParams<{
    merchantAccountId: string
  }>()

  const {
    data: totalAuthrate,
    isLoading: isLoadingTotalAuthrate,
    isError: isErrorTotalAuthrate,
  } = useQuery({
    queryKey: [INSIGHTS_AUTH_TOTAL_AUTHRATE, merchantAccountId, filters.period],
    queryFn: () =>
      getInsightsAuthTotals('auth_rate', {
        period: filters.period as Period,
      }),
    select: transformSeriesData,
    meta: {
      error: {
        ignore: [404],
      },
    },
    enabled: !!filters.period && filters.filterBy === 'authRate',
  })

  const dateRange = useMemo(
    () => generateDateRange(totalAuthrate?.series),
    [totalAuthrate?.series]
  )

  const series = useMemo(
    () =>
      generateSeries({
        series: totalAuthrate?.series,
        period: filters.period as Period,
      }),
    [totalAuthrate?.series, filters.period]
  )

  return {
    totalAuthrate,
    isLoadingTotalAuthrate,
    isErrorTotalAuthrate,
    authrateDateRange: dateRange,
    authrateSeries: series,
  }
}
