import { ReactNode } from 'react'
import { useSearchParams } from 'react-router-dom'
import { InsightsFilters } from 'insights/constants/filter'
import { useFilters } from 'shared/hooks'
import { InsightsFilterContext } from './InsightsFilterContext'

const InsightsFilterProvider = ({ children }: { children: ReactNode }) => {
  const [searchParams] = useSearchParams()
  const currentCurrency = searchParams.get('currency')
  const [filters, setFilters] = useFilters<InsightsFilters>({
    filterBy: searchParams.get('filterBy') || 'transactions',
    period: searchParams.get('period') || '7-days',
    ...(currentCurrency && { currency: currentCurrency }),
  })

  return (
    <InsightsFilterContext.Provider
      value={{
        filters,
        setFilters,
      }}
    >
      {children}
    </InsightsFilterContext.Provider>
  )
}

export default InsightsFilterProvider
