import { addDays, addHours } from 'date-fns'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { flushSync } from 'react-dom'
import { createRoot } from 'react-dom/client'
import { TooltipContent } from 'home/components/TooltipContent'
import { Period } from 'home/services/health-dashboard'
import { ConfigProps } from 'shared/constants/line-chart-config'
import type { TooltipComponentFormatterCallbackParams } from 'echarts'

dayjs.extend(duration)

export const tooltipFormatter = (
  params: TooltipComponentFormatterCallbackParams,
  numberFormat?: ConfigProps['numberFormat']
) => {
  if (!Array.isArray(params)) {
    return ''
  }

  const container = document.createElement('div')
  const root = createRoot(container)

  flushSync(() => {
    root.render(<TooltipContent params={params} numberFormat={numberFormat} />)
  })

  return container?.innerHTML || ''
}

export const generateHourRange = (timestamp: string, hours: number) =>
  Array.from({ length: hours }).map((_, index) =>
    addHours(new Date(timestamp), index).toISOString()
  )

export const generateDayRange = (timestamp: string, days: number) =>
  Array.from({ length: days }).map((_, index) =>
    addDays(new Date(timestamp), index).toISOString()
  )

export const getDuration = (period: Period | string, length: number) => ({
  days: dayjs.duration(period).get('days') * length,
  hours: dayjs.duration(period).get('hours') * length,
})

export const getSeriesNameByPeriod = (period: Period) =>
  ({
    '1-day': { current: 'Last 24 hours', previous: 'Previous 24 hours' },
    '7-days': { current: 'Last 7 days', previous: 'Previous 7 days' },
    '30-days': { current: 'Last 30 days', previous: 'Previous 30 days' },
  })[period]

export const nullifyZeroedData = (data: number[] | null[]) => {
  const uniqueValues = new Set(data)
  if (uniqueValues.size === 1 && uniqueValues.has(0)) {
    return Array.from({ length: data.length }, () => null)
  }

  return data
}
