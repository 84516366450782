import { Container, Stack } from '@gr4vy/poutine-react'
import { Helmet } from 'react-helmet'
import { DataVisualization } from 'home/components/DataVisualization'
import { Tables } from 'home/components/Tables'
import { TopSection } from 'home/components/TopSection'
import HomeFilterProvider from 'home/contexts/HomeFilterProvider'
import { useGettingStarted } from 'transactions/hooks/use-getting-started'

type HomePageProps = {
  title: string
}

const HomePage = ({ title }: HomePageProps) => {
  const { showGettingStarted, hasTransactions } = useGettingStarted()

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <HomeFilterProvider>
        <Container>
          <Stack flexDirection="column" paddingY={16} gap={32}>
            <TopSection
              showGettingStarted={showGettingStarted}
              hasTransactions={hasTransactions}
            />
            <DataVisualization showGettingStarted={showGettingStarted} />
            <Tables />
          </Stack>
        </Container>
      </HomeFilterProvider>
    </>
  )
}

export default HomePage
