import { Divider, Dropdown, Heading, Icon, Stack } from '@gr4vy/poutine-react'
import { useNavigate } from 'react-router-dom'
import { pathTo } from 'admin/paths'
import { useSession } from 'session'
import {
  AccessLevel,
  Resource,
  useResourcePermissions,
} from 'shared/permissions'
import { useMultiMerchant } from 'shared/permissions/hooks/use-multi-merchant'
import { Menu } from './Menu'

export const SettingsMenu = () => {
  const navigate = useNavigate()
  const { isStaff } = useSession()

  const {
    merchantAccountId,
    hasMultipleMerchantAccounts,
    canAccessMultiMerchant,
  } = useMultiMerchant()

  const [canAccessMerchants, canAccessUsers, canAccessIntegrations] =
    useResourcePermissions(
      [Resource.merchantAccounts, Resource.users, Resource.apiKeyPairs],
      AccessLevel.write
    )

  return (
    <Menu tooltipContent="Settings" trigger={<Icon name="settings" />}>
      <Stack direction="column" gap={8} padding={16} color="gray100">
        <Stack paddingX={8} direction="column">
          <Heading level={6} as="h6">
            Settings
          </Heading>
        </Stack>
        <Divider />
        <Dropdown.Item
          opensInModal
          onSelect={() => navigate(pathTo.merchant(merchantAccountId))}
          disabled={!canAccessMerchants}
        >
          Edit merchant
        </Dropdown.Item>
        {(hasMultipleMerchantAccounts || canAccessMultiMerchant || isStaff) && (
          <Dropdown.Item
            onSelect={() => navigate(pathTo.merchants)}
            disabled={!canAccessMerchants}
          >
            Manage merchants
          </Dropdown.Item>
        )}
        <Dropdown.Item
          onSelect={() => navigate(pathTo.users)}
          disabled={!canAccessUsers}
        >
          Manage users
        </Dropdown.Item>
        <Dropdown.Item
          onSelect={() => navigate(pathTo.apiKeys)}
          disabled={!canAccessIntegrations}
        >
          Manage integrations
        </Dropdown.Item>
      </Stack>
    </Menu>
  )
}
