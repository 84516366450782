import { useEffect } from 'react'
import { routes as homeRoutes } from 'home'
import { routes as insightsRoutes } from 'insights'
import { useInsightsFeature } from 'insights/hooks/use-insights-feature'
import { Route } from 'shared/constants/routes'
import { useHealthDashboardStatus } from 'shared/hooks/use-health-dashboard-status'
import { HOME_PATH } from 'shared/paths/home'
import { INSIGHTS_PATH } from 'shared/paths/insights'

export const useFeatureFlagRoutes = (routes: Route[]) => {
  const { isHealthDashboardEnabled } = useHealthDashboardStatus()
  const { isInsightsFeatureEnabled } = useInsightsFeature()

  const { hasInsightsFeatureRoute, hasHealthDashboardRoute } = routes.reduce(
    (acc, { path }) => ({
      hasInsightsFeatureRoute:
        acc.hasInsightsFeatureRoute || path.includes(INSIGHTS_PATH),
      hasHealthDashboardRoute:
        acc.hasHealthDashboardRoute || path.includes(HOME_PATH),
    }),
    { hasInsightsFeatureRoute: false, hasHealthDashboardRoute: false }
  )

  useEffect(() => {
    if (isHealthDashboardEnabled && !hasHealthDashboardRoute) {
      routes.unshift(...homeRoutes)
    }
    if (isInsightsFeatureEnabled && !hasInsightsFeatureRoute) {
      routes.splice(1, 0, ...insightsRoutes)
    }
  }, [
    hasHealthDashboardRoute,
    hasInsightsFeatureRoute,
    isHealthDashboardEnabled,
    isInsightsFeatureEnabled,
    routes,
  ])
}
