import { Grid, Heading, Stack } from '@gr4vy/poutine-react'
import { ReactNode } from 'react'

export interface SectionProps {
  children: ReactNode
  title: string
}

export const Section = ({ children, title }: SectionProps) => {
  return (
    <Stack gap={16} width="full">
      <Heading level={3}>{title}</Heading>
      <Grid>{children}</Grid>
    </Stack>
  )
}
