import { AccessLevel, Resource } from 'shared/permissions/constants'
import { useResourcePermission } from 'shared/permissions/hooks/use-resource-permission'

export const useWebhookSubscriptionPermission = () => {
  const canManageWebhookSubscriptions = useResourcePermission(
    Resource.webhookSubscriptions,
    AccessLevel.write
  )
  const canReadWebhookSubscriptions = useResourcePermission(
    Resource.webhookSubscriptions,
    AccessLevel.read
  )

  return {
    canManageWebhookSubscriptions,
    canReadWebhookSubscriptions,
  }
}
