import { Flex, Stack } from '@gr4vy/poutine-react'
import { useContext, useEffect } from 'react'
import { GettingStartedBanner } from 'admin/components/GettingStartedBanner'
import { TimestampLabel } from 'home/components/TimestampLabel'
import { HomeFilterContext } from 'home/contexts/HomeFilterContext'
import { useStatus } from 'home/hooks/use-status'
import { Selector } from 'shared/components/Selector'
import { formatTimestampLabel } from 'shared/helpers/line-chart-format'

export interface TopSectionProps {
  showGettingStarted: boolean
  hasTransactions: boolean
}

export const TopSection = ({
  showGettingStarted,
  hasTransactions,
}: TopSectionProps) => {
  const { filters, setFilters } = useContext(HomeFilterContext)
  const { status, isLoading, isError } = useStatus()

  const { date, time } = formatTimestampLabel(
    status?.latestExecution?.timestamp
  )

  useEffect(() => {
    if (!!status.currencies.length) {
      setFilters({
        currency: status.currencies.at(0)?.value,
        ...filters,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status.currencies])

  const isSelectorDisabled =
    isLoading ||
    isError ||
    (showGettingStarted && !hasTransactions) ||
    !status.currencies.length

  return (
    <Stack flexDirection="column" gap={40}>
      {showGettingStarted && (
        <GettingStartedBanner hasTransactions={hasTransactions} />
      )}
      <Flex justifyContent="space-between" alignItems="center">
        <Selector
          currencies={status.currencies}
          disabled={isSelectorDisabled}
          filterContext={HomeFilterContext}
        />
        {(!showGettingStarted || (showGettingStarted && hasTransactions)) &&
          !isError && <TimestampLabel date={date} time={time} />}
      </Flex>
    </Stack>
  )
}
