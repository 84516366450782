import { Box, Flex, Heading, Skeleton, Stack } from '@gr4vy/poutine-react'
import { ColorBlue60, ColorGray60 } from '@gr4vy/poutine-tokens'
import { capitalize } from 'lodash'
import { useContext } from 'react'
import { FilterBy } from 'insights/constants/insights'
import { InsightsFilterContext } from 'insights/contexts/InsightsFilterContext'
import { InsightsSummary, Period } from 'insights/services/insights'
import currencyFormat from 'shared/helpers/currency-format'
import { getSeriesNameByPeriod } from 'shared/helpers/line-chart'
import { getPercentageTrend } from 'shared/helpers/line-chart-format'
import { DataSetIndicator } from './DataSetIndicator'

export interface TotalsProps extends InsightsSummary {
  showGettingStarted?: boolean
  value: FilterBy
  loading?: boolean
}

export const Totals = ({
  showGettingStarted,
  current,
  previous,
  delta,
  value,
  loading,
}: TotalsProps) => {
  const {
    filters: { currency, filterBy, period },
  } = useContext(InsightsFilterContext)
  const currencyOptions: Intl.NumberFormatOptions = {
    currency: currency,
    currencyDisplay: 'narrowSymbol',
  }

  const getFormattedValue = (rawValue: number | null | undefined) => {
    rawValue = showGettingStarted ? 0 : rawValue

    switch (value) {
      case 'volume':
        return currencyFormat(rawValue, currencyOptions)
      case 'transactions':
        return rawValue
      case 'authRate':
        rawValue = showGettingStarted ? null : rawValue
        return typeof rawValue === 'number' ? `${rawValue}%` : '-'
      default:
        return null
    }
  }

  return (
    <Stack gap={16}>
      <Heading level={3}>
        {capitalize(filterBy?.replace(/([A-Z])/g, ' $1'))}
      </Heading>
      <Stack gap={32}>
        <Stack gap={4}>
          <DataSetIndicator color={ColorBlue60}>Current</DataSetIndicator>
          <Stack gap={8}>
            {loading ? (
              <LoadingValue />
            ) : (
              <Heading level={4}>{getFormattedValue(current?.value)}</Heading>
            )}
            {!showGettingStarted && !!delta && (
              <Flex gap={8} alignItems="center">
                {getPercentageTrend(value, delta)}
                {getSeriesNameByPeriod(period as Period)?.previous}
              </Flex>
            )}
          </Stack>
        </Stack>
        <Box>
          <DataSetIndicator color={ColorGray60}>Previous</DataSetIndicator>
          <Stack gap={4}>
            {loading ? (
              <LoadingValue />
            ) : (
              <Heading level={4}>{getFormattedValue(previous?.value)}</Heading>
            )}
          </Stack>
        </Box>
      </Stack>
    </Stack>
  )
}

const LoadingValue = () => (
  <Skeleton width={80}>
    <Skeleton.Box borderRadius="rounded" height={24} />
  </Skeleton>
)
