import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { listWebhookSubscriptions } from 'integrations/services/webhookSubscriptions'
import { WEBHOOK_SUBSCRIPTIONS } from 'shared/constants/query-keys'
import { useWebhookSubscriptionPermission } from './use-webhook-subscription-permission'

export const useWebhookSubscription = () => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const { canReadWebhookSubscriptions } = useWebhookSubscriptionPermission()

  const page = useQuery({
    queryKey: [WEBHOOK_SUBSCRIPTIONS, merchantAccountId],
    queryFn: () => listWebhookSubscriptions(),
    refetchOnWindowFocus: false,
    meta: {
      error: {
        redirect: true,
      },
    },
    enabled: canReadWebhookSubscriptions,
  })

  return {
    page,
    canReadWebhookSubscriptions,
  }
}
