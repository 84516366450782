import { Flex, Heading } from '@gr4vy/poutine-react'
import { clsx } from 'clsx'
import { capitalize } from 'lodash'
import { Context, useContext } from 'react'
import { HomeFilters } from 'home/constants/filter'
import { FilterContextProps } from 'shared/contexts/CreateFilterContext'
import { Select, SelectProps } from './Select'
import styles from './Selector.module.scss'

export interface SelectorProps<T> {
  disabled?: boolean
  currencies: SelectProps['options']
  filterByOptions?: Array<'volume' | 'transactions' | 'authRate'>
  filterContext: Context<FilterContextProps<T>>
}

export const Selector = <T extends HomeFilters>({
  disabled,
  currencies,
  filterByOptions = ['volume', 'transactions'],
  filterContext,
}: SelectorProps<T>) => {
  const { filters, setFilters } = useContext(filterContext)
  const availableCurrencies = currencies.length
    ? currencies
    : [{ value: 'N/A', label: 'N/A' }]
  const initialCurrency =
    availableCurrencies[0].value === 'N/A'
      ? undefined
      : availableCurrencies[0].value

  return (
    <Flex alignItems="center">
      <Heading level={4} className={clsx(disabled && styles.disabled)}>
        Showing{' '}
      </Heading>
      <Select
        value={filters.filterBy}
        onSelect={(filterBy) =>
          setFilters({
            ...filters,
            ...(filterBy === 'volume' && { tab: 'volume' }),
            ...(filterBy === 'transactions' && { tab: 'transactions' }),
            filterBy,
            currency: filters.currency ?? initialCurrency,
          })
        }
        options={filterByOptions.map((filterBy) => ({
          label: capitalize(filterBy.replace(/([A-Z])/g, ' $1').toLowerCase()),
          value: filterBy,
        }))}
        lowerCaseLabel
        disabled={disabled}
      />
      {filters.filterBy === 'volume' && (
        <>
          <Heading level={4} className={clsx(disabled && styles.disabled)}>
            {' '}
            processed in{' '}
          </Heading>
          <Select
            value={filters.currency}
            onSelect={(currency) => setFilters({ ...filters, currency })}
            options={[
              {
                label: 'Currency used in transaction',
                value: 'currency',
                disabled: true,
              },
              ...availableCurrencies,
            ]}
            disabled={disabled || availableCurrencies[0].value === 'N/A'}
          />
        </>
      )}
      <Heading level={4} className={clsx(disabled && styles.disabled)}>
        {' '}
        for the{' '}
      </Heading>
      <Select
        value={filters.period || '7-days'}
        onSelect={(period) => setFilters({ ...filters, period })}
        optionLabelProp="label"
        lowerCaseLabel
        options={[
          {
            label: 'Last 24 hours',
            value: '1-day',
          },
          {
            label: 'Last 7 days',
            value: '7-days',
          },
          {
            label: 'Last 30 days',
            value: '30-days',
          },
        ]}
        disabled={disabled}
      />
    </Flex>
  )
}
