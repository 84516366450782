import {
  Button,
  Heading,
  Icon,
  Stack,
  Tag,
  TextLine,
  Tooltip,
} from '@gr4vy/poutine-react'
import { useState } from 'react'
import { ColumnDef, DataTable, TimeDate } from 'shared/components/DataTable'
import { Panel } from 'shared/components/Panel'
import { EventDrawer } from 'transactions/components/EventDrawer'
import {
  formatEventName,
  getColorByMethod,
  getColorByStatusCode,
} from 'transactions/helpers'
import { useTransactionEvents } from 'transactions/hooks/use-transaction-events'
import {
  PaymentMethod,
  Transaction,
  TransactionEvent,
} from 'transactions/services'
import EventsConnection from './EventsConnection'

const EventsPanel = ({
  transaction,
  paymentMethod,
}: {
  transaction: Transaction
  paymentMethod?: PaymentMethod
}) => {
  const [event, setEvent] = useState<TransactionEvent>()
  const { tableData } = useTransactionEvents(transaction)

  const columns: Array<ColumnDef<TransactionEvent>> = [
    {
      id: 'connection',
      accessorKey: 'connection',
      size: 128,
      header: 'Service',
      cell: ({ row }: { row: any }) => {
        return <EventsConnection event={row.original} />
      },
    },
    {
      id: 'name',
      accessorKey: 'name',
      size: 600,
      header: 'Event',
      cell: ({ row }) => {
        const event = row.original
        const title = formatEventName(row.original.name)

        if (
          event.name == 'payment-connector-external-transaction-request' &&
          (event.context['url'] || event.context['method'])
        ) {
          return (
            <Stack direction="row" gap={8}>
              {event.context['method'] && (
                <Tag
                  variant="subtle"
                  color={getColorByMethod(event.context['method'])}
                >
                  {event.context['method']}
                </Tag>
              )}
              {event.context['url'] && (
                <TextLine fontFamily="mono" variant="med3">
                  {new URL(event.context['url']).pathname}
                </TextLine>
              )}
              {event.context['responseStatusCode'] && (
                <Tag
                  variant={'subtle'}
                  color={getColorByStatusCode(
                    String(event.context['responseStatusCode'])
                  )}
                >
                  {event.context['responseStatusCode']}
                </Tag>
              )}
              {event.context['errorCode'] && (
                <Tag variant={'subtle'} color="negative">
                  {event.context['errorCode']}
                </Tag>
              )}
            </Stack>
          )
        }

        return title
      },
    },
    {
      id: 'createdAt',
      accessorKey: 'createdAt',
      size: 128,
      header: 'Date',
      cell: ({ renderValue }) => {
        const createdAt = renderValue<TransactionEvent['createdAt']>()

        return createdAt ? <TimeDate value={createdAt} /> : '–'
      },
    },
    {
      id: 'actions',
      size: 40,
      cell: ({ row }: { row: any }) => {
        return (
          <Stack alignItems="end">
            <Tooltip content="Open event details">
              <Button
                variant="tertiary"
                size="small"
                onClick={() => setEvent(row.original)}
              >
                <Icon name="arrow-right-md" />
              </Button>
            </Tooltip>
          </Stack>
        )
      },
    },
  ]

  const onDrawerClose = () => setEvent(undefined)

  return tableData.length ? (
    <>
      <Panel>
        <Panel.Header>
          <Heading level={4}>Events</Heading>
        </Panel.Header>
        <Panel.Content>
          <DataTable data={{ items: tableData }} columns={columns} />
        </Panel.Content>
      </Panel>

      <EventDrawer
        open={!!event}
        title={event && formatEventName(event.name)}
        event={event}
        transaction={transaction}
        paymentMethod={paymentMethod}
        onClose={onDrawerClose}
      />
    </>
  ) : (
    <></>
  )
}

export default EventsPanel
