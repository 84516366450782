import { Flex } from '@gr4vy/poutine-react'
import { useContext } from 'react'
import { Tabs } from 'home/components/Tabs'
import { HomeFilterContext } from 'home/contexts/HomeFilterContext'
import { useHealthDashboard } from 'home/hooks/use-health-dashboard'
import { LineChart } from 'shared/components/LineChart'
import { DataVisualizationGettingStarted } from './DataVisualizationGettingStarted'

export interface DataVisualizationProps {
  showGettingStarted: boolean
}

export const DataVisualization = ({
  showGettingStarted,
}: DataVisualizationProps) => {
  const { filters, setFilters } = useContext(HomeFilterContext)
  const { summary, filterBy, getLineChartData, getTriggerProps } =
    useHealthDashboard()

  const tabsValue = filters.tab || filters.filterBy || 'volume'

  return (
    <Flex alignItems="center">
      {showGettingStarted ? (
        <DataVisualizationGettingStarted />
      ) : (
        <Tabs
          value={tabsValue}
          onValueChange={(tab) => setFilters({ ...filters, tab })}
        >
          <Flex gap={24}>
            <Tabs.List>
              {filterBy === 'volume' ? (
                <Tabs.Trigger {...getTriggerProps('volume', summary)} />
              ) : (
                <Tabs.Trigger {...getTriggerProps('transactions', summary)} />
              )}
              <Tabs.Trigger {...getTriggerProps('authRate', summary)} />
              <Tabs.Trigger {...getTriggerProps('refunds', summary)} />
            </Tabs.List>
            <Tabs.Content value={filterBy}>
              {filterBy === 'volume' ? (
                <LineChart {...getLineChartData(filterBy, 'volume')} />
              ) : (
                <LineChart {...getLineChartData(filterBy, 'transactions')} />
              )}
            </Tabs.Content>
            <Tabs.Content value="authRate">
              <LineChart {...getLineChartData(filterBy, 'authRate')} />
            </Tabs.Content>
            <Tabs.Content value="refunds">
              <LineChart {...getLineChartData(filterBy, 'refunds')} />
            </Tabs.Content>
          </Flex>
        </Tabs>
      )}
    </Flex>
  )
}
