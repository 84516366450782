import { snakeCase } from 'lodash'
import { PaymentServiceField } from 'connections/services'

export const fieldsObjToArray = (fields: Record<string, string>) =>
  Object.keys(fields)
    .map((key) => ({
      key: snakeCase(key),
      value: fields[key as keyof typeof fields],
    }))
    .filter((entry) => !!entry.value)

export const fieldsToRecord = (
  fields?: PaymentServiceField[] | null
): Record<string, string> => {
  if (!fields || !fields.length) {
    return {}
  }

  return fields.reduce(
    (acc, { key, value }) => {
      acc[key] = value
      return acc
    },
    {} as Record<string, string>
  )
}
