import { useQuery } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { usePaymentServiceDefinitions } from 'connections/hooks'
import { listPaymentServices } from 'connections/services'
import { PAYMENT_SERVICES, FIVE_MINUTES } from 'shared/constants'
import { useFilters, Filters } from 'shared/hooks'
import TransactionColumnFilter, {
  ConnectionItemProps,
  FilterKey,
} from 'transactions/components/TransactionColumnFilter/TransactionColumnFilter'

type TransactionConnectionFilterProps = {
  label: string
}

interface TransactionFilters extends Filters {
  method?: string
  paymentServiceId?: string
}

const DELETED = 'deleted'

const TransactionConnectionFilter = ({
  label,
}: TransactionConnectionFilterProps) => {
  const [filters, setFilters] = useFilters<TransactionFilters>()
  const { paymentServiceDefinitions } = usePaymentServiceDefinitions()

  const paymentServices = useQuery({
    queryKey: [PAYMENT_SERVICES],
    queryFn: () =>
      listPaymentServices({
        limit: 100,
      }),
    staleTime: FIVE_MINUTES,
  })

  const deletedPaymentServices = useQuery({
    queryKey: [PAYMENT_SERVICES, DELETED],
    queryFn: () =>
      listPaymentServices({
        limit: 100,
        deleted: true,
      }),

    staleTime: FIVE_MINUTES,
  })

  const getUniqueConnections = useCallback(() => {
    const uniqueConnections = new Set()

    return [
      ...(paymentServices.data?.items ?? []),
      ...(deletedPaymentServices.data?.items ?? []),
    ]
      .reduce(
        (
          acc: ConnectionItemProps[],
          {
            id,
            active,
            method,
            displayName,
            paymentServiceDefinitionId,
            isDeleted,
          }
        ) => {
          const isActive = !isDeleted && active
          const currDisplayName =
            displayName ||
            paymentServiceDefinitions[paymentServiceDefinitionId]
              ?.displayName ||
            ''
          if (
            uniqueConnections.has(
              `${paymentServiceDefinitionId}:${isActive}:${currDisplayName}`
            )
          ) {
            return acc
          }

          uniqueConnections.add(
            `${paymentServiceDefinitionId}:${isActive}:${currDisplayName}`
          )
          return [
            ...acc,
            {
              id,
              method,
              active: isActive,
              displayName: currDisplayName,
              iconUrl:
                paymentServiceDefinitions[paymentServiceDefinitionId]?.iconUrl,
            },
          ]
        },
        []
      )
      .sort((a, b) =>
        a.displayName.localeCompare(b.displayName, undefined, {
          sensitivity: 'base',
        })
      )
      .sort((a, b) => Number(b.active) - Number(a.active))
      .sort(
        (a, b) =>
          Number(b.id === filters.paymentServiceId) -
          Number(a.id === filters.paymentServiceId)
      )
  }, [
    deletedPaymentServices.data?.items,
    paymentServiceDefinitions,
    paymentServices.data?.items,
    filters?.paymentServiceId,
  ])

  const handleToggleFilter = async (connection: ConnectionItemProps) => {
    setFilters({
      ...filters,
      paymentServiceId:
        (filters as TransactionFilters)?.paymentServiceId === connection.id
          ? undefined
          : connection.id,
    })
  }

  const filter = useMemo(
    () => ({
      key: FilterKey.Connection,
      value: (filters as TransactionFilters)?.paymentServiceId,
    }),
    [filters]
  )

  return (
    <TransactionColumnFilter
      data={getUniqueConnections()}
      label={label}
      isLoading={paymentServices.isLoading || deletedPaymentServices.isLoading}
      onToggleFilter={handleToggleFilter}
      filter={filter}
    />
  )
}

export default TransactionConnectionFilter
