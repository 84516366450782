import { generatePath } from 'react-router-dom'
export const INSIGHTS_PATH = '/merchants/:merchantAccountId/insights'
export const INSIGHTS_AUTHORIZATION_PATH = `${INSIGHTS_PATH}/authorization`

export const pathTo = {
  insights: (merchantAccountId: string) =>
    generatePath(INSIGHTS_PATH, { merchantAccountId }),
  authorization: (merchantAccountId: string) =>
    generatePath(INSIGHTS_AUTHORIZATION_PATH, { merchantAccountId }),
}
