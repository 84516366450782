import { Button, Drawer, Flex, Stack, Text } from '@gr4vy/poutine-react'
import { UseMutationResult } from '@tanstack/react-query'
import { Form, Input, Select } from 'antd'
import { useEffect, useMemo } from 'react'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import {
  AddVaultForwardConfigAuthentication,
  UpdateVaultForwardConfigAuthentication,
  VaultForwardConfig,
  VaultForwardConfigAuthentication,
  VaultForwardConfigAuthenticationField,
  VaultForwardDefinition,
} from 'shared/services/vault-forward'
import { AuthenticationDrawerMethodFields } from './AuthenticationDrawerMethodFields'

type FormValues = Pick<
  VaultForwardConfigAuthentication,
  'displayName' | 'kind' | 'fields'
>

export type AuthenticationDrawerProps = {
  open: boolean
  onClose: () => void
  create: UseMutationResult<
    VaultForwardConfigAuthentication,
    any,
    AddVaultForwardConfigAuthentication,
    unknown
  >
  update: UseMutationResult<
    VaultForwardConfigAuthentication,
    any,
    UpdateVaultForwardConfigAuthentication,
    unknown
  >
  definition?: VaultForwardDefinition
  config?: VaultForwardConfig
  authentication?: VaultForwardConfigAuthentication
}

export const AuthenticationDrawer = ({
  open,
  onClose,
  create,
  update,
  definition,
  config,
  authentication,
}: AuthenticationDrawerProps) => {
  const [form] = Form.useForm<FormValues>()
  const kind = Form.useWatch('kind', form)
  const { isPending, isSuccess } = create
  const isEdit = !!authentication
  const title = isEdit
    ? 'Edit authentication method'
    : 'Add an authentication method'

  const onCancel = () => {
    form.resetFields()
    create.reset()
    onClose()
  }

  const handleSubmit = (values: FormValues) => {
    if (!config) {
      return
    }
    const { merchantAccountId, id } = config
    const { fields, kind, ...rest } = values
    const normalizedFields = fields
      .map((field) =>
        Object.entries(field).reduce(
          (_, [key, value]) => ({ key, value }),
          {} as Pick<VaultForwardConfigAuthenticationField, 'key' | 'value'>
        )
      )
      .filter(
        (field) => field.value || field.value === ''
      ) as VaultForwardConfigAuthenticationField[]

    if (isEdit) {
      update.mutate({
        merchantAccountId,
        id,
        authenticationId: authentication.id,
        ...(normalizedFields.length ? { fields: normalizedFields } : {}),
        ...rest,
      })
    } else {
      create.mutate({
        merchantAccountId,
        id,
        kind,
        fields: normalizedFields,
        ...rest,
      })
    }
  }

  const updateFieldValue = (field: any, value: string) => {
    form.setFieldValue(field, value)
  }

  const mapServiceToFormValues = (service?: VaultForwardConfigAuthentication) =>
    ({
      ...service,
      fields: fields?.map((field) => {
        const { key } = field
        const value = service?.fields?.find((field) => field.key === key)?.value
        return {
          [key]: value ?? undefined,
        }
      }),
    }) as FormValues

  const fields = useMemo(
    () =>
      definition?.authentications?.find(
        (authenticationDefinition) =>
          authenticationDefinition.kind === (kind || authentication?.kind)
      )?.fields,
    [definition, kind, authentication]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onCancel, [isSuccess])

  useEffect(() => {
    form.resetFields()

    if (isEdit) {
      const mappedValues = mapServiceToFormValues(authentication)
      form.setFieldsValue(mappedValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, authentication, form])

  return (
    <Drawer
      open={open}
      title={title}
      footer={
        <Flex gap={16} justifyContent="flex-end">
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            form="add-edit-authentication"
            disabled={isPending}
            loading={isPending}
            loadingText={authentication ? 'Saving' : 'Adding'}
          >
            {authentication ? 'Save' : 'Add'}
          </Button>
        </Flex>
      }
      onClose={onCancel}
    >
      <Stack gap={32}>
        <Stack gap={8}>
          <Text variant="reg3" color="gray90">
            Before you can use Vault Forward, you may need to activate each
            different kind of additional authentication.
          </Text>
        </Stack>
        <Form
          name="add-edit-authentication"
          form={form}
          onFinish={handleSubmit}
          autoComplete="off"
          layout={'vertical'}
          requiredMark={false}
        >
          <Stack gap={24}>
            <FormItem
              name="displayName"
              label={<Label>Name</Label>}
              required
              rules={[
                { required: true, message: 'Enter authentication name.' },
                {
                  type: 'string',
                  max: 255,
                  message: 'Enter a maximum of 255 characters.',
                },
              ]}
            >
              <Input autoFocus />
            </FormItem>
            {!isEdit && (
              <FormItem
                name="kind"
                label={<Label>Authentication method</Label>}
                required
                rules={[
                  { required: true, message: 'Select authentication method' },
                ]}
              >
                <Select
                  placeholder="Select a method"
                  dropdownStyle={{ pointerEvents: 'auto' }}
                  aria-label="Select a method"
                >
                  {definition?.authentications.map(({ title, kind }) => (
                    <Select.Option key={kind} value={kind}>
                      {title}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            )}
            <AuthenticationDrawerMethodFields
              fields={fields}
              updateFieldValue={updateFieldValue}
              isEdit={isEdit}
            />
          </Stack>
        </Form>
      </Stack>
    </Drawer>
  )
}
