import { useContext, useEffect } from 'react'
import { FilterBy } from 'insights/constants/insights'
import { InsightsFilterContext } from 'insights/contexts/InsightsFilterContext'
import { generateTransactionsDateRange } from 'insights/helpers/line-chart-data'
import { useAuthTotalTransactions } from 'insights/hooks/use-auth-total-transactions'
import { useAuthTotalVolume } from 'insights/hooks/use-auth-total-volume'
import { InsightsSummary, InsightsTotalData } from 'insights/services/insights'
import { LineChartProps } from 'shared/constants/line-chart-config'
import { useAuthTotalAuthrate } from './use-auth-total-authrate'

export const useDataVisualizationData = () => {
  const { filters, setFilters } = useContext(InsightsFilterContext)
  const {
    totalVolume,
    isLoadingTotalVolume,
    isErrorTotalVolume,
    volumeSeries,
    volumeDateRange,
  } = useAuthTotalVolume(filters)
  const {
    totalTransactions,
    isLoadingTotalTransactions,
    isErrorTotalTransactions,
    transactionsSeries,
    transactionsDateRange,
  } = useAuthTotalTransactions(filters)
  const {
    totalAuthrate,
    isLoadingTotalAuthrate,
    isErrorTotalAuthrate,
    authrateSeries,
    authrateDateRange,
  } = useAuthTotalAuthrate(filters)

  useEffect(() => {
    const series = totalVolume?.series || totalTransactions?.series
    if (series) {
      const { afterCreatedAt, beforeCreatedAt } =
        generateTransactionsDateRange(series)

      setFilters({
        ...filters,
        afterCreatedAt,
        beforeCreatedAt,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalVolume?.series, totalTransactions?.series, totalAuthrate?.series])

  const getTotalsData = (
    value: FilterBy,
    summary: InsightsSummary | undefined
  ): InsightsTotalData =>
    ({
      volume: {
        ...summary,
        value,
        disabled: isLoadingTotalVolume || isErrorTotalVolume,
        loading: isLoadingTotalVolume,
      },
      transactions: {
        ...summary,
        value,
        disabled: isLoadingTotalTransactions || isErrorTotalTransactions,
        loading: isLoadingTotalTransactions,
      },
      authRate: {
        ...summary,
        value,
        disabled: isLoadingTotalAuthrate || isErrorTotalAuthrate,
        loading: isLoadingTotalAuthrate,
      },
    })[value]

  const getLineChartData = (filterBy: FilterBy): LineChartProps =>
    ({
      volume: {
        data: volumeDateRange,
        series: volumeSeries.volume,
        loading: isLoadingTotalVolume,
        numberFormat: 'decimalCompact' as const,
        showLegend: false,
      },
      transactions: {
        data: transactionsDateRange,
        series: transactionsSeries.transactions,
        loading: isLoadingTotalTransactions,
        numberFormat: 'decimal' as const,
        showLegend: false,
      },
      authRate: {
        data: authrateDateRange,
        series: authrateSeries.authRate,
        loading: isLoadingTotalAuthrate,
        numberFormat: 'percent' as const,
        showLegend: false,
      },
    })[filterBy]

  const filterBy =
    !!filters.filterBy &&
    ['volume', 'transactions', 'authRate'].includes(filters.filterBy)
      ? (filters.filterBy as FilterBy)
      : 'volume'

  let summary
  switch (filterBy) {
    case 'volume':
      summary = totalVolume?.summary
      break
    case 'transactions':
      summary = totalTransactions?.summary
      break
    case 'authRate':
      summary = totalAuthrate?.summary
      break
  }

  return {
    summary,
    getTotalsData,
    getLineChartData,
    filterBy,
  }
}
