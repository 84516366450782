import { useEffect, useRef, useState } from 'react'
import { InsightsModuleData } from 'insights/services/insights'

interface UseTableWrapperHeightProps {
  data: InsightsModuleData[]
  error: boolean | undefined
  loading: boolean | undefined
}

export function useTableBodyHeight({
  data,
  error,
  loading,
}: UseTableWrapperHeightProps) {
  const tableWrapperRef = useRef<HTMLDivElement>(null)
  const [tableBodyHeight, setTableBodyHeight] = useState(0)

  useEffect(() => {
    if ((error || loading) && data.length > 0) {
      const ROW_HEIGHT = 56
      const PREVIOUS_TBODY_HEIGHT = data.length * ROW_HEIGHT
      const CURRENT_TBODY_HEIGHT = tableWrapperRef.current?.offsetHeight
        ? tableWrapperRef.current.offsetHeight - ROW_HEIGHT
        : 0

      if (
        CURRENT_TBODY_HEIGHT &&
        PREVIOUS_TBODY_HEIGHT > CURRENT_TBODY_HEIGHT
      ) {
        setTableBodyHeight(PREVIOUS_TBODY_HEIGHT)
      }
    }
  }, [error, loading, data])

  return {
    tableWrapperRef,
    tableBodyHeight: tableBodyHeight ? `${tableBodyHeight}px` : 'auto',
  }
}
