import { HTMLAttributes } from 'react'
import styles from './Meter.module.scss'

interface MeterProps extends HTMLAttributes<HTMLDivElement> {
  value: number
  minValue?: number
  maxValue: number
  label?: string
}

export const Meter = ({
  value,
  minValue = 0,
  maxValue,
  label = 'Progress meter',
  ...rest
}: MeterProps) => {
  const clampedValue = Math.min(Math.max(value, minValue), maxValue)

  const percentageForStyle =
    ((clampedValue - minValue) / (maxValue - minValue)) * 100

  return (
    <div
      className={styles.bar}
      role="meter"
      aria-valuenow={clampedValue}
      aria-valuemin={minValue}
      aria-valuemax={maxValue}
      aria-label={label}
      {...rest}
    >
      <div
        className={styles.fill}
        style={{ width: `${percentageForStyle}%` }}
      />
    </div>
  )
}
