import { Resource } from 'shared/permissions/constants'
import { HomePage } from './pages'

export default [
  {
    title: 'Home',
    longTitle: 'Home',
    key: 'home',
    path: '/merchants/:merchantAccountId/home',
    menu: true,
    component: HomePage,
    resource: Resource.healthDashboard,
  },
]
