import { Input } from 'antd'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { Uploader } from 'shared/components/Uploader'
import { VaultForwardConfigAuthenticationField } from 'shared/services/vault-forward'

export type AuthenticationDrawerMethodFieldsProps = {
  fields?: VaultForwardConfigAuthenticationField[]
  updateFieldValue: (field: any, value: string) => void
  isEdit: boolean
}

export const AuthenticationDrawerMethodFields = ({
  fields,
  updateFieldValue,
  isEdit,
}: AuthenticationDrawerMethodFieldsProps) => {
  const handleUploaderChange = async (files: FileList | null, field: any) => {
    const file = files?.[0]
    if (file) {
      const fileReader = new FileReader()
      fileReader.readAsText(file)
      fileReader.onload = (e: any) => updateFieldValue(field, e.target.result)
    }
  }

  return (
    <>
      {fields?.map(({ key, displayName, format, secret, required }, index) => (
        <FormItem
          key={key}
          name={['fields', index, key]}
          label={<Label>{displayName}</Label>}
          required={required}
          editable={secret && isEdit}
          secret={secret}
          rules={[{ required, message: 'Enter value.' }]}
        >
          {format === 'file' ? (
            <Uploader
              onChange={(files) =>
                handleUploaderChange(files, ['fields', index, key])
              }
              variant="small"
            >
              Drop your file here or click to browse
            </Uploader>
          ) : (
            <Input />
          )}
        </FormItem>
      ))}
    </>
  )
}
