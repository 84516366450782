import { GridItem } from '@gr4vy/poutine-react'
import {
  ColorBlue10,
  ColorGray100,
  RadiusRounded,
  Size32,
} from '@gr4vy/poutine-tokens/js/tokens'
import { Select, SelectProps, Tag } from 'antd'
import { ComponentProps, MouseEvent } from 'react'
import { Operation } from 'flows/constants'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { maxTagPlaceholderFormatter } from 'shared/helpers/maxTagPlaceholderFormatter'

interface CardIssuerOrProductNameProps {
  name: number
  placeholder: string
}

type TagRenderProps = ComponentProps<NonNullable<SelectProps['tagRender']>>

const ERROR_OPERATOR_REQUIRED = 'Operator is required'

function tagRender({ label, closable, onClose }: TagRenderProps) {
  const onPreventMouseDown = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }
  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: '3px',
        background: ColorBlue10,
        borderColor: ColorBlue10,
        borderRadius: RadiusRounded,
        color: ColorGray100,
        height: Size32,
        lineHeight: Size32,
        marginTop: 0,
      }}
    >
      {label}
    </Tag>
  )
}

const CardIssuerOrProductName = ({
  name,
  placeholder,
}: CardIssuerOrProductNameProps) => (
  <>
    <GridItem gridColumn="span 2">
      <FormItem
        label={<Label>Operator</Label>}
        name={[name, 'operator']}
        rules={[{ required: true, message: ERROR_OPERATOR_REQUIRED }]}
      >
        <Select placeholder="Select an operator">
          <Select.Option value={Operation.LIKE_ONE_OF}>
            like one of
          </Select.Option>
          <Select.Option value={Operation.UNLIKE_ANY_OF}>
            unlike any of
          </Select.Option>
        </Select>
      </FormItem>
    </GridItem>
    <GridItem gridColumn="span 6">
      <FormItem
        label={<Label>Value(s)</Label>}
        name={[name, 'value']}
        rules={[
          {
            required: true,
            message: 'At least one value is required',
          },
        ]}
      >
        <Select
          mode="tags"
          allowClear
          tagRender={tagRender}
          maxTagCount="responsive"
          maxTagPlaceholder={maxTagPlaceholderFormatter}
          placeholder={placeholder}
        />
      </FormItem>
    </GridItem>
  </>
)

export { ERROR_OPERATOR_REQUIRED }
export default CardIssuerOrProductName
