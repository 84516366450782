import { useParams, Navigate } from 'react-router-dom'
import { pathToInsights } from 'shared/paths'
import {
  INSIGHTS_PATH,
  INSIGHTS_AUTHORIZATION_PATH,
} from 'shared/paths/insights'
import { Resource } from 'shared/permissions/constants'
import { InsightsPage } from './pages'

export default [
  {
    title: 'Insights',
    longTitle: 'Insights',
    key: 'insights',
    path: INSIGHTS_PATH,
    menu: false,
    component: function Default() {
      const { merchantAccountId } = useParams() as {
        merchantAccountId: string
      }

      return (
        <Navigate
          replace
          to={pathToInsights.authorization(merchantAccountId)}
        />
      )
    },
    resource: Resource.insights,
  },
  {
    title: 'Insights',
    longTitle: 'Insights',
    key: 'insights-authorization',
    path: INSIGHTS_AUTHORIZATION_PATH,
    menu: true,
    component: InsightsPage,
    resource: Resource.insights,
  },
]
