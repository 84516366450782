import {
  Button,
  Drawer,
  Flex,
  Heading,
  Stack,
  Text,
} from '@gr4vy/poutine-react'
import { Form, Input, Select } from 'antd'
import { useEffect } from 'react'
import { useMutateWebhookSubscription } from 'integrations/hooks/use-mutate-webhook-subscription'
import { WebhookSubscription } from 'integrations/services/webhookSubscriptions'
import { CredentialField } from 'shared/components/CredentialField/CredentialField'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { is } from 'shared/helpers/is'
import { useMerchantAccounts } from 'shared/permissions/hooks/use-merchant-accounts'

type FormValues = {
  url: string
  merchantAccountId: string
  authentication?: WebhookSubscription['authentication']
}

export type WebhookSubscriptionDrawerProps = {
  open: boolean
  onClose: () => void
  subscription?: WebhookSubscription
}

export const WebhookSubscriptionDrawer = ({
  open,
  onClose,
  subscription,
}: WebhookSubscriptionDrawerProps) => {
  const [form] = Form.useForm<FormValues>()
  const authenticationPassword = Form.useWatch(
    ['authentication', 'password'],
    form
  )
  const { create, update } = useMutateWebhookSubscription()
  const isPending = create.isPending || update.isPending
  const { merchantAccounts } = useMerchantAccounts()
  const isEdit = !!subscription
  const title = isEdit
    ? 'Edit webhook subscription'
    : 'Add a webhook subscription'

  if (!merchantAccounts.find((ma) => ma.id === '*')) {
    merchantAccounts.unshift({ id: '*', displayName: 'All merchants' } as any)
  }

  const handleSubmit = (values: FormValues) => {
    const { merchantAccountId, url } = values
    const username = values.authentication?.username || ''
    const password = values.authentication?.password || ''
    const authentication =
      !username && !password
        ? null
        : {
            kind: 'basic',
            username,
            password,
          }

    if (isEdit) {
      update.mutate(
        {
          url,
          ...(form.isFieldsTouched(['authentication'])
            ? { authentication }
            : {}),
          id: subscription?.id,
          merchantAccountId,
        },
        {
          onSuccess: onCancel,
        }
      )
    } else {
      create.mutate(
        {
          url,
          authentication,
          merchantAccountId,
        },
        {
          onSuccess: onCancel,
        }
      )
    }
  }

  const onCancel = () => {
    form.resetFields()
    create.reset()
    onClose()
  }

  useEffect(() => {
    form.resetFields()

    if (isEdit && subscription) {
      form.setFieldsValue(subscription)
    }
  }, [isEdit, subscription, form])

  return (
    <Drawer
      open={open}
      title={title}
      onClose={onCancel}
      footer={
        <Flex gap={16} justifyContent="flex-end">
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button form="add-edit-webhook-subscription" loading={isPending}>
            {subscription ? 'Update' : 'Add'}
          </Button>
        </Flex>
      }
    >
      <Stack gap={32}>
        <Stack gap={8}>
          <Text variant="reg3" color="gray90">
            In order for your webhook to function, ensure you enter all details
            precisely, as no verification is performed when you add this to the
            system.
          </Text>
        </Stack>
        <Form
          name="add-edit-webhook-subscription"
          form={form}
          onFinish={handleSubmit}
          autoComplete="off"
          layout={'vertical'}
          requiredMark={false}
        >
          <Stack gap={24}>
            <Stack gap={24}>
              <FormItem
                name="url"
                label={<Label>Subscription URL</Label>}
                required
                rules={[
                  {
                    required: true,
                    message: 'Please enter your subscription URL',
                  },
                  {
                    type: 'url',
                    message: 'This field must be a valid url',
                  },
                ]}
              >
                <Input autoFocus placeholder="Enter your subscription URL" />
              </FormItem>
              {!isEdit && (
                <FormItem
                  name="merchantAccountId"
                  label={<Label>Merchant account ID</Label>}
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Please select a merchant account ID',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select a merchant account ID"
                    dropdownStyle={{ pointerEvents: 'auto' }}
                    aria-label="Select a merchant account ID"
                  >
                    {merchantAccounts?.map(({ id, displayName }) => (
                      <Select.Option key={id} value={id}>
                        {displayName}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              )}
            </Stack>
            <Stack gap={24}>
              <Heading as="h2" level={5}>
                Basic authentication (optional)
              </Heading>
              <FormItem
                name={['authentication', 'username']}
                label={<Label>Username</Label>}
              >
                <Input autoFocus placeholder="Enter the username" />
              </FormItem>
              <CredentialField
                item={{
                  key: 'password',
                  displayName: <Label>Password</Label>,
                }}
                required={false}
                onEdit={(isEditing) => {
                  form.setFieldValue(
                    ['authentication', 'password'],
                    isEditing ? '' : undefined
                  )
                }}
                secret={
                  !!subscription?.authentication?.password &&
                  !is.emptyString(authenticationPassword)
                }
                hasShowHideToggle={
                  !subscription?.authentication?.password ||
                  is.emptyString(authenticationPassword) ||
                  form.isFieldTouched(['authentication', 'password'])
                }
                placeholder="Enter the password"
                group="authentication"
              />
            </Stack>
          </Stack>
        </Form>
      </Stack>
    </Drawer>
  )
}
