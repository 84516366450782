import { Stack } from '@gr4vy/poutine-react'
import { useEffect } from 'react'
import styles from 'shared/components/LineChart/LineChart.module.scss'
import { gettingStartedConfig } from 'shared/constants/line-chart-config'
import { useLineChart } from 'shared/hooks'

export const LineChartGettingStarted = () => {
  const {
    echart: { lineChartRef, echartsInstance },
  } = useLineChart({
    loading: false,
    data: [],
    series: [],
  })

  useEffect(() => {
    if (echartsInstance) {
      echartsInstance.setOption(gettingStartedConfig, true)
    }
  }, [echartsInstance])

  return (
    <Stack width="full" position="relative" asChild>
      <div>
        <div ref={lineChartRef} className={styles.lineChart} />
      </div>
    </Stack>
  )
}
